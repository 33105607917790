import { call, put,takeLatest} from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {axios} from "../app/index";
import {normalize} from "../../utils/normalize";

import {
    CODE_EXAM
} from "./reducer";

export function* getCodeExamWorker(){
    try {
        const code_exam = yield call(axios.get,"/submit_form/genrateExamCode");
        const requested = normalize(code_exam);
        yield put({
            type: success(CODE_EXAM),
            payload:{
                code_exam:requested
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;
        yield put({
            type:error(CODE_EXAM),
            payload:{error:message}
        })
    }
}
export function* conditionSaga() {
    yield takeLatest(CODE_EXAM, getCodeExamWorker);

}

  