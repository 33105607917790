import { routerReducer } from "react-router-redux";
import appReducer from "../modules/app/reducer";
import userReducer from "../modules/user/reducer";
import homeReducer from '../modules/home/reducer'
import condiReducer from '../modules/conditions/reducer'
import paymentReducer from '../modules/payment/reducer'
import profileReducer from '../modules/profile/reducer'
import dashboardReducer from "../modules/dashboard/reducer";

// import skuProduct from "../modules/skuProduct/reducers"
export default {
  app: appReducer,
  user: userReducer,
  router: routerReducer,
  home:homeReducer,
  condistion:condiReducer,
  payment:paymentReducer,
  profile:profileReducer,
  dashboard: dashboardReducer,
};
