/* eslint-disable no-useless-escape */
import React from 'react';
import CryptoJS from 'crypto-js';
import wingAxios from 'axios';
import { call} from "redux-saga/effects";

export function* callWING(payload) {
  console.log('-==',payload);   
  try {
        const referer = Buffer.from("http://localhost:3006").toString('base64');
        const checkTranWING =yield call(wingAxios.post,`https://sdkuat.wingmoney.com/wingonlinesdkv2/v1/online/payment/authorization`,payload,
          {headers:{
            'Referer': referer,
          }}
        );
      console.log('-==',checkTranWING);   
      // yield put({
      //     type: success(CHECK_TRANSACTION_ABA),
      //     payload:{
      //         checkTranABA:normalize(checkTranABA)
      //     }
      // });
  } catch (e) {
    console.log(e);
  }
}
export default class ScreenLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wingToken: '',
      modalWing:false,
      urlEncodedData: "",
      htmlContent:''
    }};

  randomString(len, charSet) {
    charSet =
      charSet || "0123456789abcdef0123456789abcdef0123456789abcdef";
    var randomString = "";
    for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }

  async payment(json){
    const base64Hash = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(this.state.wingToken));
    const url=`https://sdk-uat.wingmoney.com/wingonlinesdkv2/v1/online/payment/confirm?token=${base64Hash}`
    const response = await fetch(url);
    const text = await response.text();
    this.setState({htmlContent:text,modalWing:true});
  }
  handleClose () {
    this.setState({htmlContent:'',modalWing:false});
  };
  render() {
    return (
      <div className="container_homePage">
        {
          this.state.modalWing?
          <div style={{ position: 'absolute',backgroundColor:'#DDD',minWidth:'100%',padding:50}}>
            <div className='col-lg-6 col-md-6 col-sm-12' style={{ position: 'absolute', top: '10px', right: '50px',zIndex:10000,alignItems: 'flex-end',    justifyContent: 'end',paddingRight:'0',
    display: 'flex',
    direction: 'ltr' }}>
              <button
              style={{
                height: "35px",
                backgroundColor: "#FFF",
                color:"#DC143C",
                borderWidth: "0px",
                borderRadius: "3px",
                width: "100px",
                fontSize:12,
                fontFamily: 'Odor Mean Chey',
              }}
              onClick={()=>this.handleClose()} >
                  បោះបង់                
              </button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />
          </div>
          :null
        }
        <input onChange={(e)=>this.setState({wingToken:e.target.value})}></input>
        <button onClick={()=>this.payment(false)}>
          AAAA
        </button>
        {/* {this.state.modalWing ?
              <Iframe url={`https://sdk-uat.wingmoney.com/wingonlinesdkv2/v1/online/payment/confirm?token=${this.state.wingToken}`}
                width="100%"
                height={600}
                id="iframeContainer"
                display="initial"
                position="relative"
                allowFullScreen
                loading={"សូមរង់ចាំ"}
              /> : null
            } */}
      </div>
    );
  }
}
