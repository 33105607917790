import React from 'react';
import './style.css';
import { Grid, DialogContent,DialogActions, DialogTitle, Dialog } from '@material-ui/core';
import moment from 'moment/moment';
export default function TransitionsModal({ open, handleClose, handleSave, data,titleButtonContinue }) {
      const width = window.innerWidth;
      return (
            <div>
                  <Dialog
                        className='preview_form'
                        fullScreen={width <= 575 ? true : false}
                        open={open}
                        scroll={"paper"}
                        aria-labelledby="scroll-dialog-title"
                        aria-describedby="scroll-dialog-description"
                  >
                        <DialogTitle id="scroll-dialog-title">
                              <h3 id="transition-modal-title" className='title_verify'>សូមធ្វើការផ្ទៀងផ្ទាត់ព័ត៌មានរបស់អ្នក</h3>
                        </DialogTitle>
                        <DialogContent dividers={'paper'}>
                              <Grid container>
                                    <Grid item xs={12}>
                                          <img alt='profile' src={data?.showProfile} className='profile_image_pro_preview'></img>
                                    </Grid>
                                    <div className='blog_info_member_profile'>
                                          <Grid container spacing={2}>
                                                <Grid item sm={12} xs={12}><h6 className='profileName'>នាមត្រកូល និងនាមខ្លួនជាអក្សរឡាតាំង </h6></Grid>
                                                <Grid item sm={12} xs={12}><h4 className='profileName'>{data?.fullname}</h4></Grid>
                                          </Grid>
                                    </div>
                                    <div className='blog_info_member'>
                                          <Grid container>
                                                <Grid item sm={6} xs={6}>
                                                      <h6><span className='txtRed'>*</span> ភេទ ៖</h6>
                                                </Grid>
                                                <Grid item sm={6} xs={6}>
                                                      <h6><span className='txtRed'>*</span> ថ្ងៃខែឆ្នាំកំណើត ៖</h6></Grid>
                                                <Grid item sm={6} xs={6}> <h5>{(data?.gender==="Male" || data?.gender==="male")?"ប្រុស":'ស្រី'}</h5></Grid>
                                                <Grid item xs={6}><h3 className='txtNumberPreview'>{data?.birthday ?moment(data.birthday).format('DD-MM-YYYY'):null }</h3></Grid>

                                                <Grid item xs={12}><h6><span className='txtRed'>*</span> លេខទូរស័ព្ទ ៖</h6></Grid>
                                                <Grid item xs={12}><h3 className='txtNumberPreview'>{data?.phone}</h3></Grid>

                                                <Grid item xs={12}><h6><span className='txtRed'>*</span> លេខអត្តសញ្ញាណប័ណ្ណ /
                                                      លិខិតឆ្លងដែន ៖</h6></Grid>
                                                <Grid item xs={12}><h3 className='txtNumberPreview'>{data?.passport}</h3></Grid>
                                          </Grid>
                                    </div>
                                    <div className='blog_info_member_passport'>
                                          <Grid container>
                                                <Grid item xs={12}><h6><span className='txtRed'>* </span>រូបថតអត្តសញ្ញាណប័ណ្ណ ឬលិខិតឆ្លងដែន</h6></Grid>
                                                <Grid item xs={12}>
                                                      <img alt='passport' src={data?.showID} className='image_review_card'></img>
                                                </Grid>
                                          </Grid>
                                    </div>
                              </Grid>
                        </DialogContent>
                        <DialogActions className='previewButtom'>
                              <button className='button_preview_edit' onClick={() => handleClose()}>
                                    កែប្រែ
                              </button>
                              <button className='button_preview_payment' onClick={() => handleSave()}>
                                   {titleButtonContinue?titleButtonContinue:'បន្តបង់ប្រាក់'} 
                              </button>
                        </DialogActions>
                  </Dialog>
            </div>
      )
}
