import { success, error, abort } from "redux-saga-requests";

export const GET_MENU_ADMIN = "GET_MENU_ADMIN"
export const ADD_MENU_ADMIN = "ADD_MENU_ADMIN"
export const UPDATE_MENU_ADMIN = "UPDATE_MENU_ADMIN"
export const DELETE_MENU_ADMIN = "DELETE_MENU_ADMIN"

export const GET_MENU_ADMIN_PAGE = "GET_MENU_ADMIN_PAGE"
export const GET_USER = "GET_USER"
export const ADD_USER = "ADD_USER"
export const UPDATE_USER_ADMIN = "UPDATE_USER_ADMIN"
export const DELETE_USER = "DELETE_USER"

export const GET_USER_PERMISSION = "GET_USER_PERMISSION"
export const UPDATE_USER_PERMISSION = "UPDATE_USER_PERMISSION"

export const GET_ACCOUNT_USER = "GET_ACCOUNT_USER"
export const UPDATE_ACCOUNT_USER = "UPDATE_ACCOUNT_USER"
export const DELETE_ACCOUNT_USER = "DELETE_ACCOUNT_USER"
export const SEARCH_ACCOUNT_USER = "SEARCH_ACCOUNT_USER"
export const COUNT_ACCOUNT_USER = "COUNT_ACCOUNT_USER"


export const GET_FORM = "GET_FORM"
export const UPDATE_FORM = "UPDATE_FORM"
export const DELETE_FORM = "DELETE_FORM"
export const ZIP_NOW = "ZIP_NOW";
export const ZIP_CHECK = "ZIP_CHECK";
export const JOB_CATEGORY = "JOB_CATEGORY"
export const JOB_SUB_CATEGORY = "JOB_SUB_CATEGORY"
export const SEARCH_FORM = "SEARCH_FORM"
export const DOWNLOAD_DATA = "DOWNLOAD_DATA"
export const FORM_COUNT = "FORM_COUNT";


export const JOB_CATEGORY_SELECT = "JOB_CATEGORY_SELECT";
export const JOB_CATEGORY_INSERT = "JOB_CATEGORY_INSERT";
export const JOB_CATEGORY_DELETE = "JOB_CATEGORY_DELETE";
export const JOB_CATEGORY_UPDATE = "JOB_CATEGORY_UPDATE";

export const SPECIAL_MEMBERS_SELECT = "SPECIAL_MEMBERS_SELECT";
export const SPECIAL_MEMBERS_INSER = "SPECIAL_MEMBERS_INSER";
export const SPECIAL_MEMBERS_DELETE = "SPECIAL_MEMBERS_DELETE";
export const SPECIAL_MEMBERS_UPDATE = "SPECIAL_MEMBERS_UPDATE";
export const SPECIAL_EXAM_SELECT = "SPECIAL_EXAM_SELECT";
export const SPECIAL_EXAM_COUNT = "SPECIAL_EXAM_COUNT";


export const SCHEDULE_EXAM_SELECT = "SCHEDULE_EXAM_SELECT";
export const SCHEDULE_EXAM_COUNT = "SCHEDULE_EXAM_COUNT";
export const SCHEDULE_EXAM_INSERT = "SCHEDULE_EXAM_INSERT";
export const SCHEDULE_EXAM_UPDATE = "SCHEDULE_EXAM_UPDATE";
export const DELETE_DATA_TABLE = "DELETE_DATA_TABLE";

export const GET_YOUTUBE_VIDEO = "GET_YOUTUBE_VIDEO"
export const UPDATE_YOUTUBE_VIDEO = "UPDATE_YOUTUBE_VIDEO"

export const GET_SENT_NOTIFICATION = "GET_SENT_NOTIFICATION"
export const GET_COUNT_NOTIFICATION = "GET_COUNT_NOTIFICATION"
export const SEND_NOTIFICATION = "SEND_NOTIFICATION"
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION"

export const GET_COUNT_MEMBERS = "GET_COUNT_MEMBERS"

export const GET_SEARCH_SUBMIT_MEMBER = "GET_SEARCH_SUBMIT_MEMBER"
export const INSERT_MEMBER = "INSERT_MEMBER";
export const GET_SCHEDULE_EXAM = "GET_SCHEDULE_EXAM";

export const GET_VERSION_APP = "GET_VERSION_APP";
export const UPDATE_VERSION_APP = "UPDATE_VERSION_APP";

export const GET_SCHEDULE_MEMBERS = "GET_SCHEDULE_MEMBERS";
export const GET_SCHEDULE_MEMBERS_DOWNLOAD = "GET_SCHEDULE_MEMBERS_DOWNLOAD";

export const SCHEDULE_MEMBER_LOCKED_SELECT = "SCHEDULE_MEMBER_LOCKED_SELECT";
export const SCHEDULE_MEMBER_LOCKED_INSERT = "SCHEDULE_MEMBER_LOCKED_INSERT";
export const SCHEDULE_MEMBER_LOCKED_DELETE = "SCHEDULE_MEMBER_LOCKED_DELETE";
export const SCHEDULE_MEMBER_LOCKED_UPDATE = "SCHEDULE_MEMBER_LOCKED_UPDATE";

export const TRANSACTIONS_SELECT = "TRANSACTIONS_SELECT";

export const getMenuAdmin = () => ({
  type: GET_MENU_ADMIN,
});

export const getMenuAdminPage = () => ({
  type: GET_MENU_ADMIN_PAGE,
});

export const addMenuAdmin = (payload) => ({
  type: ADD_MENU_ADMIN,
  payload
});

export const updateMenuAdmin = (payload) => ({
  type: UPDATE_MENU_ADMIN,
  payload
});

export const deleteMenuAdmin = (payload) => ({
  type: DELETE_MENU_ADMIN,
  payload
});



export const getUser = () => ({
  type: GET_USER,
});

export const addUser = (payload) => ({
  type: ADD_USER,
  payload
});

export const updateUser = (payload) => ({
  type: UPDATE_USER_ADMIN,
  payload
});

export const deleteUser = (payload) => ({
  type: DELETE_USER,
  payload
});



export const getUserPermission = (payload) => ({
  type: GET_USER_PERMISSION,
  payload
});

export const updatePermission = (payload) => ({
  type: UPDATE_USER_PERMISSION,
  payload
});

export const getJobCategory_f = () => ({
  type: JOB_CATEGORY
});

export const getJobSubCategory_f = (payload) => ({
  type: JOB_SUB_CATEGORY,
  payload
});



export const getAccontUser_f = (payload) => ({
  type: GET_ACCOUNT_USER,
  payload
});
export const countAccontUser_f = (payload) => ({
  type: COUNT_ACCOUNT_USER,
  payload
});
export const updateAccountUser_f = (payload) => ({
  type: UPDATE_ACCOUNT_USER,
  payload
});

export const deleteAccountUser_f = (payload) => ({
  type: DELETE_ACCOUNT_USER,
  payload
});
export const searchAccontUser_f = (payload) => ({
  type: SEARCH_ACCOUNT_USER,
  payload
});

export const getForm_f = (payload) => ({
  type: GET_FORM,
  payload
});
export const getFormCount_f = () => ({
  type: FORM_COUNT
});
export const searchForm_f = (payload) => ({
  type: SEARCH_FORM,
  payload
});
export const downloadData_f = (payload) => ({
  type: DOWNLOAD_DATA,
  payload
});

export const zip_f = (payload) => ({
  type: ZIP_NOW,
  payload
});
export const check_zip_f = (payload) => ({
  type: ZIP_CHECK,
  payload
});
export const updateForm_f = (payload) => ({
  type: UPDATE_FORM,
  payload
});

export const deleteForm_f = (payload) => ({
  type: DELETE_FORM,
  payload
});


export const select_job_categories_f = (payload) => ({
  type: JOB_CATEGORY_SELECT,
  payload
});
export const insert_job_categories_f = (payload) => ({
  type: JOB_CATEGORY_INSERT,
  payload
});
export const update_job_categories_f = (payload) => ({
  type: JOB_CATEGORY_UPDATE,
  payload
});
export const delete_job_categories_f = (payload) => ({
  type: JOB_CATEGORY_DELETE,
  payload
});


export const select_special_exam_f = () => ({
  type: SPECIAL_EXAM_SELECT
});
export const select_special_members_f = (payload) => ({
  type: SPECIAL_MEMBERS_SELECT,
  payload
});
export const insert_special_members_f = (payload) => ({
  type: SPECIAL_MEMBERS_INSER,
  payload
});
export const update_special_members_f = (payload) => ({
  type: SPECIAL_MEMBERS_UPDATE,
  payload
});
export const delete_special_members_f = (payload) => ({
  type: SPECIAL_MEMBERS_DELETE,
  payload
});
export const count_special_members_f = (payload) => ({
  type: SPECIAL_EXAM_COUNT,
  payload
});




export const select_schedule_exam_f = (payload) => ({
  type: SCHEDULE_EXAM_SELECT,
  payload
});
export const count_schedule_exam_f = (payload) => ({
  type: SCHEDULE_EXAM_COUNT,
  payload
});
export const insert_schedule_exam_f = (payload) => ({
  type: SCHEDULE_EXAM_INSERT,
  payload
});
export const update_schedule_exam_f = (payload) => ({
  type: SCHEDULE_EXAM_UPDATE,
  payload
});
export const delete_table_f = (payload) => ({
  type: DELETE_DATA_TABLE,
  payload
});

export const get_video_youtube_f = () => ({
  type: GET_YOUTUBE_VIDEO
});
export const update_video_youtube_f = (payload) => ({
  type: UPDATE_YOUTUBE_VIDEO,
  payload
});
export const get_sent_notifications_f = (payload) => ({
  type: GET_SENT_NOTIFICATION,
  payload
});
export const get_delete_notifications_f = (payload) => ({
  type: DELETE_NOTIFICATION,
  payload
});
export const get_count_notifications_f = () => ({
  type: GET_COUNT_NOTIFICATION
});
export const send_notification_f = (payload) => ({
  type: SEND_NOTIFICATION,
  payload
});

export const get_search_submit_member_f = (payload) => ({
  type: GET_SEARCH_SUBMIT_MEMBER,
  payload
});
export const get_insert_submit_member_f = (payload) => ({
  type: INSERT_MEMBER,
  payload
});
export const get_schedule_submit_member_f = () => ({
  type: GET_SCHEDULE_EXAM,
});

export const get_count_member_f = (payload) => ({
  type: GET_COUNT_MEMBERS,
  payload
});

export const get_version_app_f = (payload) => ({
  type: GET_VERSION_APP,
  payload
});

export const update_version_app_f = (payload) => ({
  type: UPDATE_VERSION_APP,
  payload
});

export const schedule_members_f = (payload) => ({
  type: GET_SCHEDULE_MEMBERS,
  payload
});

export const schedule_members_donwload_f = (payload) => ({
  type: GET_SCHEDULE_MEMBERS_DOWNLOAD,
  payload
})


export const select_schedule_members_locked_f = (payload) => ({
  type: SCHEDULE_MEMBER_LOCKED_SELECT,
  payload
});
export const insert_schedule_members_locked_f = (payload) => ({
  type: SCHEDULE_MEMBER_LOCKED_INSERT,
  payload
});
export const update_schedule_members_locked_f = (payload) => ({
  type: SCHEDULE_MEMBER_LOCKED_UPDATE,
  payload
});
export const delete_schedule_members_locked_f = (payload) => ({
  type: SCHEDULE_MEMBER_LOCKED_DELETE,
  payload
});

export const select_transactions_f = (payload) => ({
  type: TRANSACTIONS_SELECT,
  payload
});


const initialState = {
  loading: false,
  error: false,
  message: false,
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
    * GET_MENU_ADMIN
    */
    case GET_MENU_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_MENU_ADMIN):
      return { ...state, loading: false };

    /**
 * GET_MENU_ADMIN_PAGE
 */
    case GET_MENU_ADMIN_PAGE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_MENU_ADMIN_PAGE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_MENU_ADMIN_PAGE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_MENU_ADMIN_PAGE):
      return { ...state, loading: false };

    /**
  * ADD_MENU_ADMIN
  */
    case ADD_MENU_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
        add_menu_admin: false
      };
    case success(ADD_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ADD_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ADD_MENU_ADMIN):
      return { ...state, loading: false };


    /**
  * UPDATE_MENU_ADMIN
  */
    case UPDATE_MENU_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
        update_menu_admin: false
      };
    case success(UPDATE_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_MENU_ADMIN):
      return { ...state, loading: false };


    /**
  * DELETE_MENU_ADMIN
  */
    case DELETE_MENU_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
        delete_menu_admin: false
      };
    case success(DELETE_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_MENU_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_MENU_ADMIN):
      return { ...state, loading: false };

    /**
  * GET_USER
  */
    case GET_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_USER):
      return { ...state, loading: false };

    /**
 * ADD_USER
 */
    case ADD_USER:
      return {
        ...state,
        loading: true,
        error: false,
        add_user: false
      };
    case success(ADD_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ADD_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ADD_USER):
      return { ...state, loading: false };

    /**
 * UPDATE_USER_ADMIN
 */
    case UPDATE_USER_ADMIN:
      return {
        ...state,
        loading: true,
        error: false,
        UPDATE_USER_ADMIN: false
      };
    case success(UPDATE_USER_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_USER_ADMIN):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_USER_ADMIN):
      return { ...state, loading: false };

    /**
 * DELETE_USER
 */
    case DELETE_USER:
      return {
        ...state,
        loading: true,
        error: false,
        delete_user: false
      };
    case success(DELETE_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_USER):
      return { ...state, loading: false };

    /**
 * GET_USER_PERMISSION
 */
    case GET_USER_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_USER_PERMISSION):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_USER_PERMISSION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_USER_PERMISSION):
      return { ...state, loading: false };

    /**
 * UPDATE_USER_PERMISSION
 */
    case UPDATE_USER_PERMISSION:
      return {
        ...state,
        loading: true,
        error: false,
        update_permission: false
      };
    case success(UPDATE_USER_PERMISSION):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_USER_PERMISSION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_USER_PERMISSION):
      return { ...state, loading: false };




    /**
     * GET_ACCOUNT_USER
     */
    case GET_ACCOUNT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_ACCOUNT_USER):
      return { ...state, loading: false };
    /**
   * COUNT_ACCOUNT_USER
   */
    case COUNT_ACCOUNT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(COUNT_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(COUNT_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(COUNT_ACCOUNT_USER):
      return { ...state, loading: false };

    /**
     * SEARCH_ACCOUNT_USER
     */
    case SEARCH_ACCOUNT_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SEARCH_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SEARCH_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SEARCH_ACCOUNT_USER):
      return { ...state, loading: false };

    /**
   * UPDATE_ACCOUNT_USER
   */
    case UPDATE_ACCOUNT_USER:
      return {
        ...state,
        loading: true,
        error: false,
        update_account_user: false
      };
    case success(UPDATE_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_ACCOUNT_USER):
      return { ...state, loading: false };

    /**
 * DELETE_ACCOUNT_USER
 */
    case DELETE_ACCOUNT_USER:
      return {
        ...state,
        loading: true,
        error: false,
        delete_account_user: false
      };
    case success(DELETE_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_ACCOUNT_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_ACCOUNT_USER):
      return { ...state, loading: false };



    /**
   * GET_FORM
   */
    case GET_FORM:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_FORM):
      return { ...state, loading: false };
    /**
     * FORM_COUNT
     */
    case FORM_COUNT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(FORM_COUNT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(FORM_COUNT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(FORM_COUNT):
      return { ...state, loading: false };
    /**
     * SEARCH_FORM
     */
    case SEARCH_FORM:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SEARCH_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SEARCH_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SEARCH_FORM):
      return { ...state, loading: false };
    /**  *  /**
     * DOWNLOAD_DATA
     */
    case DOWNLOAD_DATA:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(DOWNLOAD_DATA):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DOWNLOAD_DATA):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DOWNLOAD_DATA):
      return { ...state, loading: false };
    /**
        * ZIP_NOW
        */
    case ZIP_NOW:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(ZIP_NOW):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ZIP_NOW):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ZIP_NOW):
      return { ...state, loading: false };
    /**
   * ZIP_CHECK
   */
    case ZIP_CHECK:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(ZIP_CHECK):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(ZIP_CHECK):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(ZIP_CHECK):
      return { ...state, loading: false };
    /**
   * UPDATE_FORM
   */
    case UPDATE_FORM:
      return {
        ...state,
        loading: true,
        error: false,
        update_form: false
      };
    case success(UPDATE_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_FORM):
      return { ...state, loading: false };

    /**
 * DELETE_FORM
 */
    case DELETE_FORM:
      return {
        ...state,
        loading: true,
        error: false,
        delete_form: false
      };
    case success(DELETE_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_FORM):
      return { ...state, loading: false };

    /**
   * JOB_CATEGORY
   */
    case JOB_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(JOB_CATEGORY):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(JOB_CATEGORY):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(JOB_CATEGORY):
      return { ...state, loading: false };
    /**
     * JOB_SUB_CATEGORY
     */
    case JOB_SUB_CATEGORY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(JOB_SUB_CATEGORY):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(JOB_SUB_CATEGORY):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(JOB_SUB_CATEGORY):
      return { ...state, loading: false };
    /**
     * JOB_CATEGORY_SELECT
     */
    case JOB_CATEGORY_SELECT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(JOB_CATEGORY_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(JOB_CATEGORY_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(JOB_CATEGORY_SELECT):
      return { ...state, loading: false };

    /**
  * JOB_CATEGORY_INSERT
  */
    case JOB_CATEGORY_INSERT:
      return {
        ...state,
        loading: true,
        error: false,
        insert_job_categories: false
      };
    case success(JOB_CATEGORY_INSERT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(JOB_CATEGORY_INSERT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(JOB_CATEGORY_INSERT):
      return { ...state, loading: false };


    /**
     * JOB_CATEGORY_UPDATE
     */
    case JOB_CATEGORY_UPDATE:
      return {
        ...state,
        loading: true,
        error: false,
        update_job_categories: false
      };
    case success(JOB_CATEGORY_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(JOB_CATEGORY_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(JOB_CATEGORY_UPDATE):
      return { ...state, loading: false };


    /**
     * JOB_CATEGORY_DELETE
     */
    case JOB_CATEGORY_DELETE:
      return {
        ...state,
        loading: true,
        error: false,
        delete_job_categories: false
      };
    case success(JOB_CATEGORY_DELETE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(JOB_CATEGORY_DELETE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(JOB_CATEGORY_DELETE):
      return { ...state, loading: false };

    /**
     * SCHEDULE_EXAM_SELECT
     */
    case SCHEDULE_EXAM_SELECT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SCHEDULE_EXAM_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_EXAM_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_EXAM_SELECT):
      return { ...state, loading: false };
    /**
   * SCHEDULE_EXAM_COUNT
   */
    case SCHEDULE_EXAM_COUNT:
      return {
        ...state,
        count_schedule_exam: 0,
        loading: true,
        error: false,
      };
    case success(SCHEDULE_EXAM_COUNT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_EXAM_COUNT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_EXAM_COUNT):
      return { ...state, loading: false };

    /**
  * SCHEDULE_EXAM_INSERT
  */
    case SCHEDULE_EXAM_INSERT:
      return {
        ...state,
        loading: true,
        error: false,
        insert_schedule_exam: false
      };
    case success(SCHEDULE_EXAM_INSERT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_EXAM_INSERT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_EXAM_INSERT):
      return { ...state, loading: false };


    /**
     * SCHEDULE_EXAM_UPDATE
     */
    case SCHEDULE_EXAM_UPDATE:
      return {
        ...state,
        loading: true,
        error: false,
        update_schedule_exam: false
      };
    case success(SCHEDULE_EXAM_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_EXAM_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_EXAM_UPDATE):
      return { ...state, loading: false };
    /**
    * DELETE_DATA_TABLE
    */
    case DELETE_DATA_TABLE:
      return {
        ...state,
        loading: true,
        error: false,
        delete_data_table: false
      };
    case success(DELETE_DATA_TABLE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_DATA_TABLE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_DATA_TABLE):
      return { ...state, loading: false };
    /**
   * GET_YOUTUBE_VIDEO
   */
    case GET_YOUTUBE_VIDEO:
      return {
        ...state,
        loading: true,
        error: false,
        update_schedule_exam: false
      };
    case success(GET_YOUTUBE_VIDEO):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_YOUTUBE_VIDEO):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_YOUTUBE_VIDEO):
      return { ...state, loading: false };
    /**
     * UPDATE_YOUTUBE_VIDEO
     */
    case UPDATE_YOUTUBE_VIDEO:
      return {
        ...state,
        loading: true,
        error: false,
        update_video_youtube: false
      };
    case success(UPDATE_YOUTUBE_VIDEO):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_YOUTUBE_VIDEO):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_YOUTUBE_VIDEO):
      return { ...state, loading: false };


    /**
  * GET_SENT_NOTIFICATION
  */
    case GET_SENT_NOTIFICATION:
      return {
        ...state,
        loading: true,
        error: false,
        update_schedule_exam: false
      };
    case success(GET_SENT_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_SENT_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_SENT_NOTIFICATION):
      return { ...state, loading: false };
    /**
     * DELETE_NOTIFICATION
     */
    case DELETE_NOTIFICATION:
      return {
        ...state,
        loading: true,
        error: false,
        delete_notification: false
      };
    case success(DELETE_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(DELETE_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(DELETE_NOTIFICATION):
      return { ...state, loading: false };
    /**
 * GET_COUNT_NOTIFICATION
 */
    case GET_COUNT_NOTIFICATION:
      return {
        ...state,
        loading: true,
        error: false,
        get_count_notification: 0
      };
    case success(GET_COUNT_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_COUNT_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_COUNT_NOTIFICATION):
      return { ...state, loading: false };
    /**
   * SEND_NOTIFICATION
   */
    case SEND_NOTIFICATION:
      return {
        ...state,
        loading: true,
        error: false,
        send_notification: false
      };
    case success(SEND_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SEND_NOTIFICATION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SEND_NOTIFICATION):
      return { ...state, loading: false };

    /**
   * GET_COUNT_MEMBERS
   */
    case GET_COUNT_MEMBERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_COUNT_MEMBERS):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_COUNT_MEMBERS):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_COUNT_MEMBERS):
      return { ...state, loading: false };
    /**
   * SPECIAL_MEMBERS_SELECT
   */
    case SPECIAL_MEMBERS_SELECT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SPECIAL_MEMBERS_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SPECIAL_MEMBERS_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SPECIAL_MEMBERS_SELECT):
      return { ...state, loading: false };

    /**
  * SPECIAL_MEMBERS_INSER
  */
    case SPECIAL_MEMBERS_INSER:
      return {
        ...state,
        loading: true,
        error: false,
        insert_special_members: false
      };
    case success(SPECIAL_MEMBERS_INSER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SPECIAL_MEMBERS_INSER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SPECIAL_MEMBERS_INSER):
      return { ...state, loading: false };


    /**
     * SPECIAL_MEMBERS_UPDATE
     */
    case SPECIAL_MEMBERS_UPDATE:
      return {
        ...state,
        loading: true,
        error: false,
        update_special_members: false
      };
    case success(SPECIAL_MEMBERS_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SPECIAL_MEMBERS_UPDATE):
      return {
        ...state,
        ...action.payload,
        update_special_members: false,
        loading: false,
        error: true
      };
    case abort(SPECIAL_MEMBERS_UPDATE):
      return { ...state, loading: false };
    /**
   * SPECIAL_EXAM_COUNT
   */
    case SPECIAL_EXAM_COUNT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case success(SPECIAL_EXAM_COUNT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SPECIAL_EXAM_COUNT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SPECIAL_EXAM_COUNT):
      return { ...state, loading: false };

    /**
   * SPECIAL_EXAM_SELECT
   */
    case SPECIAL_EXAM_SELECT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case success(SPECIAL_EXAM_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SPECIAL_EXAM_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SPECIAL_EXAM_SELECT):
      return { ...state, loading: false };
    /**
     * GET_SEARCH_SUBMIT_MEMBER
     */
    case GET_SEARCH_SUBMIT_MEMBER:
      return {
        ...state,
        loading: true,
        error: false,
        get_search_submit_member: false
      };
    case success(GET_SEARCH_SUBMIT_MEMBER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_SEARCH_SUBMIT_MEMBER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    /**
 * INSERT_MEMBER
 */
    case INSERT_MEMBER:
      return {
        ...state,
        loading: true,
        error: false,
        insert_member: false,
        error_insert_member: false
      };
    case success(INSERT_MEMBER):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(INSERT_MEMBER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(INSERT_MEMBER):
      return { ...state, loading: false };
    /**
   * GET_SCHEDULE_EXAM
   */
    case GET_SCHEDULE_EXAM:
      return {
        ...state,
        loading: true,
        error: false
      };
    case success(GET_SCHEDULE_EXAM):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_SCHEDULE_EXAM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };



    /**
     * GET_VERSION_APP
     */
    case GET_VERSION_APP:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_VERSION_APP):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_VERSION_APP):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_VERSION_APP):
      return { ...state, loading: false };
    /**
     * UPDATE_VERSION_APP
     */
    case UPDATE_VERSION_APP:
      return {
        ...state,
        loading: true,
        error: false,
        update_version_app: false
      };
    case success(UPDATE_VERSION_APP):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_VERSION_APP):
      return {
        ...state,
        ...action.payload,
        update_version_app: false,
        loading: false,
        error: true
      };
    case abort(UPDATE_VERSION_APP):
      return { ...state, loading: false };

    /**
   * GET_SCHEDULE_MEMBERS
   */
    case GET_SCHEDULE_MEMBERS:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_SCHEDULE_MEMBERS):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_SCHEDULE_MEMBERS):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_SCHEDULE_MEMBERS):
      return { ...state, loading: false };

    /**
      * GET_SCHEDULE_MEMBERS_DOWNLOAD
      */
    case GET_SCHEDULE_MEMBERS_DOWNLOAD:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_SCHEDULE_MEMBERS_DOWNLOAD):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_SCHEDULE_MEMBERS_DOWNLOAD):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_SCHEDULE_MEMBERS_DOWNLOAD):
      return { ...state, loading: false };

    /**
     * SCHEDULE_MEMBER_LOCKED_SELECT
     */
    case SCHEDULE_MEMBER_LOCKED_SELECT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SCHEDULE_MEMBER_LOCKED_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_MEMBER_LOCKED_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_MEMBER_LOCKED_SELECT):
      return { ...state, loading: false };

    /**
   * SCHEDULE_MEMBER_LOCKED_INSERT
   */
    case SCHEDULE_MEMBER_LOCKED_INSERT:
      return {
        ...state,
        loading: true,
        error: false,
        insert_schedule_members_locked: false,
      };
    case success(SCHEDULE_MEMBER_LOCKED_INSERT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_MEMBER_LOCKED_INSERT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_MEMBER_LOCKED_INSERT):
      return { ...state, loading: false };
    /**
* SCHEDULE_MEMBER_LOCKED_UPDATE
*/
    case SCHEDULE_MEMBER_LOCKED_UPDATE:
      return {
        ...state,
        loading: true,
        error: false,
        update_schedule_members_locked: false
      };
    case success(SCHEDULE_MEMBER_LOCKED_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_MEMBER_LOCKED_UPDATE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_MEMBER_LOCKED_UPDATE):
      return { ...state, loading: false };
    /**
* SCHEDULE_MEMBER_LOCKED_DELETE
*/
    case SCHEDULE_MEMBER_LOCKED_DELETE:
      return {
        ...state,
        loading: true,
        error: false,
        delete_schedule_members_locked: false
      };
    case success(SCHEDULE_MEMBER_LOCKED_DELETE):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SCHEDULE_MEMBER_LOCKED_DELETE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SCHEDULE_MEMBER_LOCKED_DELETE):
      return { ...state, loading: false };

    /**
 * SCHEDULE_MEMBER_LOCKED_DELETE
 */
    case TRANSACTIONS_SELECT:
      return {
        ...state,
        loading: true,
        error: false,
        select_transaction:[],
        select_transaction_total:0
      };
    case success(TRANSACTIONS_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(TRANSACTIONS_SELECT):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(TRANSACTIONS_SELECT):
      return { ...state, loading: false };

    /**
     * DEFAULT CASE
     */
    default:
      return state;
  }
}

export default dashboardReducer;