
const REACT_APP_BOTTOKEN=process.env.REACT_APP_BOTTOKEN;
const REACT_APP_CHATID=process.env.REACT_APP_CHATID;

export const pushBackTelegram = (payload) => {
   
      const messageToTelegram=`${
                     new Date()+' | ' + payload?.tran_id + ' | '+ payload?.amount + ' | '+
                     payload?.device + ' | ' + payload?.phone + ' | '+
                     payload?.schedule + ' | ' +
                     payload?.userId +' | '+
                     payload?.payment+ ' | [' }`;
      fetch(
      `https://api.telegram.org/bot${REACT_APP_BOTTOKEN}/sendMessage?chat_id=${REACT_APP_CHATID}&text=${encodeURIComponent(
         messageToTelegram
      )}`
      ); 
}