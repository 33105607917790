import { all } from "redux-saga/effects";
import { appSaga } from "../modules/app";
import { userSaga } from "../modules/user";
import {homeSaga} from '../modules/home'
import {conditionSaga} from '../modules/conditions'
import {paymentSaga} from '../modules/payment'
import {profileSaga} from '../modules/profile'
import { dashboardSaga } from "../modules/dashboard";
const sagas = [
  appSaga(),
  userSaga(),
  homeSaga(),
  conditionSaga(),
  paymentSaga(),
  profileSaga(),
  dashboardSaga()
];

export default function*() {
  yield all(sagas);
}
