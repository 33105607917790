import { success, error, abort } from "redux-saga-requests";

export const CODE_EXAM='CODE_EXAM';

export const get_code_exam_f = () =>({
  type: CODE_EXAM
});
const initialState = {
  loading: false,
  error: false,
};

const condiReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * CODE_EXAM
     */
    case CODE_EXAM:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(CODE_EXAM):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(CODE_EXAM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(CODE_EXAM):
      return { ...state, loading: false };

    default:
      return state;
  }

  
  
}

export default condiReducer;