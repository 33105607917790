import { success, error, abort } from "redux-saga-requests";

export const GET_JOB_TYPE = 'GET_JOB_TYPE';
export const GET_JOB_TYPE_SEASONAL = 'GET_JOB_TYPE_SEASONAL';
export const SUB_JOB='SUB_JOB';
export const SUBMIT_FORM ="SUBMIT_FORM";
export const UPDATE_USER="UPDATE_USER";
export const VERIFY_BIRTHDATE="VERIFY_BIRTHDATE";
export const LINK_YOUTUBE="LINK_YOUTUBE";
export const PRICE="PRICE"
export const VERIFY_MEMBER="VERIFY_MEMBER";
export const VERIFY_MEMBER_SEASONAL="VERIFY_MEMBER_SEASONAL";
export const PAYMENT_SET="PAYMENT_SET";
export const VERIFY_PAY="VERIFY_PAY";
export const GET_YEARS="GET_YEARS"
export const SUBMIT_FORM_TEMPOTERY = "SUBMIT_FORM_TEMPOTERY";
export const SUBMIT_FORM_SEASONAL = "SUBMIT_FORM_SEASONAL";
export const CHECK_TRANSACTION="CHECK_TRANSACTION";
export const CHECK_TRANSACTION_ABA="CHECK_TRANSACTION_ABA";
export const WEB_PUSH_BACK="WEB_PUSH_BACK";
export const WING_GENERATE_HASH="WING_GENERATE_HASH";
export const CHECK_TRANSACTION_WING="CHECK_TRANSACTION_WING";


export const get_type_job_f = (payload) => ({
  type: GET_JOB_TYPE,
  payload
});
export const get_type_job_seasonal_f = (payload) => ({
  type: GET_JOB_TYPE_SEASONAL,
  payload
});
export const get_sub_job_f = (payload) =>({
  type: SUB_JOB,
  payload
});
export const submit_form_f = (payload) =>({
  type: SUBMIT_FORM,
  payload
});
export const update_user_f = (payload) =>({
  type: UPDATE_USER,
  payload
});
export const verify_birthdate_f = (payload) =>({
  type: VERIFY_BIRTHDATE,
  payload
});
export const linkYoutube_f = () =>({
  type: LINK_YOUTUBE,
});
export const verify_price_f = () =>({
  type: PRICE
});
export const verify_member_f = (payload) =>({
  type: VERIFY_MEMBER,
  payload
});
export const verify_member_seasonal_f = (payload) =>({
  type: VERIFY_MEMBER_SEASONAL,
  payload
});
export const payment_set_f = (payload) =>({
  type: PAYMENT_SET,
  payload
});
export const payment_verify_f = (payload) =>({
  type: VERIFY_PAY,
  payload
});
export const get_years_f = (payload) =>({
  type: GET_YEARS,
  payload
});
export const submit_form_tempotery_f = payload => ({
  type: SUBMIT_FORM_TEMPOTERY,
  payload,
});
export const submit_form_seasonal_f = payload => ({
  type: SUBMIT_FORM_SEASONAL,
  payload,
});
export const check_transaction_f = payload => ({
  type: CHECK_TRANSACTION,
  payload,
});

export const check_transaction_aba_f = payload => ({
  type: CHECK_TRANSACTION_ABA,
  payload,
});

export const web_push_back_f = payload => ({
  type: WEB_PUSH_BACK,
  payload,
});

export const wing_generate_hash_f = payload => ({
  type: WING_GENERATE_HASH,
  payload,
});

export const check_transaction_wing_f = payload => ({
  type: CHECK_TRANSACTION_WING,
  payload,
});


const initialState = {
  loading: false,
  error: false,
};

const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    /**
     * GET_JOB_TYPE
     */
    case GET_JOB_TYPE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_JOB_TYPE):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_JOB_TYPE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_JOB_TYPE):
      return { ...state, loading: false };
      /**
     * GET_JOB_TYPE_SEASONAL
     */
    case GET_JOB_TYPE_SEASONAL:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(GET_JOB_TYPE_SEASONAL):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(GET_JOB_TYPE_SEASONAL):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(GET_JOB_TYPE_SEASONAL):
      return { ...state, loading: false };
     /**
     * SUB_JOB
     */
    case SUB_JOB:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SUB_JOB):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SUB_JOB):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SUB_JOB):
      return { ...state, loading: false };
    /**
     * SUBMIT_FORM
     */
    case SUBMIT_FORM:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(SUBMIT_FORM):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(SUBMIT_FORM):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(SUBMIT_FORM):
    return { ...state, loading: false };
     /**
     * UPDATE_USER
     */
    case UPDATE_USER:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(UPDATE_USER):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(UPDATE_USER):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(UPDATE_USER):
      return { ...state, loading: false };
      /**
     * VERIFY_BIRTHDATE
     */
    case VERIFY_BIRTHDATE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(VERIFY_BIRTHDATE):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(VERIFY_BIRTHDATE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(VERIFY_BIRTHDATE):
      return { ...state, loading: false };
    /**
     * LINK_YOUTUBE
     */
    case LINK_YOUTUBE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case success(LINK_YOUTUBE):     
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    case error(LINK_YOUTUBE):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
      };
    case abort(LINK_YOUTUBE):
      return { ...state, loading: false };
      /**
       * PRICE
       */
      case PRICE:
          return {
          ...state,
          loading: true,
          error: false,
          };
      case success(PRICE):     
          return {
          ...state,
          ...action.payload,
          loading: false
          };
      case error(PRICE):
          return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
          };
      case abort(PRICE):
          return { ...state, loading: false };
           /**
       * VERIFY_MEMBER
       */
      case VERIFY_MEMBER:
          return {
          ...state,
          loading: true,
          error: false,
          };
      case success(VERIFY_MEMBER):     
          return {
          ...state,
          ...action.payload,
          loading: false
          };
      case error(VERIFY_MEMBER):
          return {
          ...state,
          ...action.payload,
          loading: false,
          error: true
          };
      case abort(VERIFY_MEMBER):
          return { ...state, loading: false };
              /**
       * VERIFY_MEMBER_SEASONAL
       */
      case VERIFY_MEMBER_SEASONAL:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(VERIFY_MEMBER_SEASONAL):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(VERIFY_MEMBER_SEASONAL):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(VERIFY_MEMBER_SEASONAL):
        return { ...state, loading: false };
      /**
       * PAYMENT_SET
       */
      case PAYMENT_SET:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(PAYMENT_SET):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(PAYMENT_SET):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(PAYMENT_SET):
        return { ...state, loading: false };
      /**
       * VERIFY_PAY
       */
      case VERIFY_PAY:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(VERIFY_PAY):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(VERIFY_PAY):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(VERIFY_PAY):
        return { ...state, loading: false };
      /**
       * GET_YEARS
       */
       case GET_YEARS:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(GET_YEARS):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(GET_YEARS):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(GET_YEARS):
        return { ...state, loading: false };
        /**
       * SUBMIT_FORM_TEMPOTERY
       */
       case SUBMIT_FORM_TEMPOTERY:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(SUBMIT_FORM_TEMPOTERY):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(SUBMIT_FORM_TEMPOTERY):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(SUBMIT_FORM_TEMPOTERY):
        return { ...state, loading: false };
        /**
       * SUBMIT_FORM_SEASONAL
       */
       case SUBMIT_FORM_SEASONAL:
        return {
        ...state,
        loading: true,
        error: false,
        };
    case success(SUBMIT_FORM_SEASONAL):     
        return {
        ...state,
        ...action.payload,
        loading: false
        };
    case error(SUBMIT_FORM_SEASONAL):
        return {
        ...state,
        ...action.payload,
        loading: false,
        error: true
        };
    case abort(SUBMIT_FORM_SEASONAL):
        return { ...state, loading: false };
          /**
     * CHECK_TRANSACTION
     */
    case CHECK_TRANSACTION:
      return {
        ...state,
        checkTranError:false,
        checkTran:false,
        loading: true,
        error: false,
      };
    case success(CHECK_TRANSACTION):
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case error(CHECK_TRANSACTION):
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: true,
      };
    case abort(CHECK_TRANSACTION):
      return { ...state, loading: false };
      /**
     * CHECK_TRANSACTION_ABA
     */
      case CHECK_TRANSACTION_ABA:
        return {
          ...state,
          checkTranABA:false,
          loading: true,
          error: false,
        };
      case success(CHECK_TRANSACTION_ABA):
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(CHECK_TRANSACTION_ABA):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true,
        };
      case abort(CHECK_TRANSACTION_ABA):
        return { ...state, loading: false };
   /**
     * WEB_PUSH_BACK
     */
   case WEB_PUSH_BACK:
    return {
      ...state,
      checkTranError:false,
      checkTran:false,
      loading: true,
      error: false,
    };
  case success(WEB_PUSH_BACK):
    return {
      ...state,
      ...action.payload,
      loading: false,
    };
  case error(WEB_PUSH_BACK):
    return {
      ...state,
      ...action.payload,
      loading: false,
      error: true,
    };
  case abort(WEB_PUSH_BACK):
    return { ...state, loading: false };

      /**
     * WING_GENERATE_HASH
     */
      case WING_GENERATE_HASH:
        return {
          ...state,
          wingHash:false,
          loading: true,
          error: false,
        };
      case success(WING_GENERATE_HASH):
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(WING_GENERATE_HASH):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true,
        };
      case abort(WING_GENERATE_HASH):
        return { ...state, loading: false };
            /**
     * CHECK_TRANSACTION_WING
     */
      case CHECK_TRANSACTION_WING:
        return {
          ...state,
          checkTranWING:false,
          loading: true,
          error: false,
        };
      case success(CHECK_TRANSACTION_WING):
        return {
          ...state,
          ...action.payload,
          loading: false,
        };
      case error(CHECK_TRANSACTION_WING):
        return {
          ...state,
          ...action.payload,
          loading: false,
          error: true,
        };
      case abort(CHECK_TRANSACTION_WING):
        return { ...state, loading: false };

    default:
      return state;
  }

  
  
}

export default homeReducer;