import { connect } from "react-redux";
import LoginScreen from "../screen/ScreenLogin"
import {appWillRefresh} from '../modules/app/reducer'
import {userLogin_f,userSigup_f} from '../modules/user/reducer'
import {linkYoutube_f} from '../modules/home/reducer'

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  userLogin_f,
  appWillRefresh,
  linkYoutube_f,
  userSigup_f
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen);
