import { call, put,takeLatest} from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {axios} from "../app/index";
import {normalize} from "../../utils/normalize";
import moment from "moment";
import CryptoJS from 'crypto-js'
import abaAxios from 'axios';
import wingAxios from 'axios';


import {pushBackTelegram} from '../../utils'

import {
    SUB_JOB,
    GET_JOB_TYPE,
    GET_JOB_TYPE_SEASONAL,
    SUBMIT_FORM,
    UPDATE_USER,
    VERIFY_BIRTHDATE,
    LINK_YOUTUBE,
    PRICE,
    VERIFY_MEMBER,
    PAYMENT_SET,
    VERIFY_PAY,
    GET_YEARS,
    SUBMIT_FORM_TEMPOTERY,
    CHECK_TRANSACTION,
    CHECK_TRANSACTION_ABA,
    WEB_PUSH_BACK,
    WING_GENERATE_HASH,
    CHECK_TRANSACTION_WING,
    VERIFY_MEMBER_SEASONAL,
    SUBMIT_FORM_SEASONAL
} from "./reducer";
// import wingAxios from 'axios';
const ABA_PAYWAY_MERCHANT_ID = process.env.REACT_APP_ABA_PAYWAY_MERCHANT_ID;
const ABA_PAYWAY_KEY = process.env.REACT_APP_ABA_PAYWAY_KEY;
const REFERER=process.env.REACT_APP_REFERER;
const REACT_APP_ABA_PAYWAY_CHECK_TRANSACTION=process.env.REACT_APP_ABA_PAYWAY_CHECK_TRANSACTION;
const HASH_KEY=process.env.REACT_APP_HASH_KEY;
const REACT_APP_WING_ONLINE_INQUIRY=process.env.REACT_APP_WING_ONLINE_INQUIRY;

export function* getTypeJobWorker({payload}) {
    try { 
        const type_job = yield call(axios.get,"/section-job/"+payload);
        const requested = normalize(type_job);
   
        yield put({
            type: success(GET_JOB_TYPE),
            payload: {
                type_job: requested,
                errorTypejob:false
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e.response));
        const message = parseError.data;
        yield put({
            type: error(GET_JOB_TYPE),
            payload: {
                errorTypejob:message
            }
        });
    }
}
export function* getTypeJobSeasonalWorker({payload}) {
    try { 
        const type_job = yield call(axios.get,"/section-job-seasonal/"+payload);
        const requested = normalize(type_job);
   
        yield put({
            type: success(GET_JOB_TYPE_SEASONAL),
            payload: {
                type_job_seasonal: requested,
                errorTypejobSeasonal:false
            }
        });
        
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e.response));
        const message = parseError.data;
        yield put({
            type: error(GET_JOB_TYPE_SEASONAL),
            payload: {
                errorTypejobSeasonal:message
            }
        });
    }
}

export function* getSubjobWorker({payload}){
    try {
        const sub_job = yield call(axios.get,"/job_type/secorndery_job/"+payload);
        const requested = normalize(sub_job);
        yield put({
            type: success(SUB_JOB),
            payload:{
                sub_job:requested
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;
        yield put({
            type:error(SUB_JOB),
            payload:{error:message}
        })
    }
}
export function* SubmitFormWorker({payload}){
    try {
        const form= new FormData();
        for (const key in payload) {
            const element = payload[key];
            form.append(key,element)
        }
        const submit = yield call(axios.post,"/submit_form",form);
        const requested = normalize(submit);
        yield put({
            type: success(SUBMIT_FORM),
            payload:{
                submit:requested
            }
        })
    } catch (error) {
  }
}   

export function* updateUserWorker(){
    try {
        const update_user_ = yield call(axios.get,"/submit_form/updateUser");
        yield put({
            type: success(UPDATE_USER),
            payload:{
                update_user_:normalize(update_user_)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;
        alert('error')

        yield put({
            type:error(UPDATE_USER),
            payload:{error:message}
        })
    }
}
export function* verifybirhtdateWorker({payload}){
    try {
        const verify_birthdate = yield call(axios.post,"/submit_form/verify",payload);
        yield put({
            type: success(VERIFY_BIRTHDATE),
            payload:{
                verify_birthdate:normalize(verify_birthdate)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;
        alert('error')

        yield put({
            type:error(VERIFY_BIRTHDATE),
            payload:{error:message}
        })
    }
}
export function* getLinkYoutubeWorker(){
    try {
        const link_youtube = yield call(axios.get,"/youtubelink");
        yield put({
            type: success(LINK_YOUTUBE),
            payload:{
                link_youtube:normalize(link_youtube)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;
        yield put({
            type:error(LINK_YOUTUBE),
            payload:{error:message}
        })
    }
}
export function* verifyPriceWorker(){
    try {
        const verify_price = yield call(axios.get,"/submit_form/price");
        yield put({
            type: success(PRICE),
            payload:{
                verify_price:normalize(verify_price)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;

        yield put({
            type:error(PRICE),
            payload:{error:message}
        })
    }
}
export function* verifyMemberWorker({payload}){
    try {
        const verify_member = yield call(axios.post,"/verify-member",payload);
        yield put({
            type: success(VERIFY_MEMBER),
            payload:{
                verify_member:normalize(verify_member),
                verify_member_error:false
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
                type:error(VERIFY_MEMBER),
                payload:{verify_member_error:message,error:message}
            })
    }
}
export function* paymentSetWorker({payload}){
    try {
        const paymentSet = yield call(axios.post,"/payment",payload);
        yield put({
            type: success(PAYMENT_SET),
            payload:{
                paymentSet:normalize(paymentSet)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;

        yield put({
            type:error(PAYMENT_SET),
            payload:{error:message}
        })
    }
}
export function* paymentVerifyWorker({payload}){
    try {
        const paymentVerify = yield call(axios.post,"/payment/verify",payload);
        yield put({
            type: success(VERIFY_PAY),
            payload:{
                paymentVerify:normalize(paymentVerify)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;

        yield put({
            type:error(VERIFY_PAY),
            payload:{error:message}
        })
    }
}
export function* getYearsWorker({payload}){
    try {
        const get_years = yield call(axios.get,"/schedule_exam/year/"+payload);
        yield put({
            type: success(GET_YEARS),
            payload:{
                get_years:normalize(get_years)
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response;

        yield put({
            type:error(GET_YEARS),
            payload:{error:message}
        })
    }
}
export function* SubmitFormTempoteryWorker({payload}){
    try {
        const form= new FormData();
        for (const key in payload) {
            const element = payload[key];
            form.append(key,element)
        }
        const dataUpload_tempotery = yield call(axios.post,"/submit-form",form);
        const requested = normalize(dataUpload_tempotery);
       
        yield put({
            type: success(SUBMIT_FORM_TEMPOTERY),
            payload:{
                dataUpload_tempotery:requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
            type:error(SUBMIT_FORM_TEMPOTERY),
            payload:{dataUpload_tempotery:message}
        });
                   
  }
}  

export function* SubmitFormSeasonalWorker({payload}){
    try {
        const form= new FormData();
        for (const key in payload) {
            const element = payload[key];
            form.append(key,element)
        }
        const dataUpload_seasonal = yield call(axios.post,"/submit-form-seasonal",form);
        const requested = normalize(dataUpload_seasonal);
       
        yield put({
            type: success(SUBMIT_FORM_SEASONAL),
            payload:{
                dataUpload_seasonal:requested,
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
            type:error(SUBMIT_FORM_SEASONAL),
            payload:{dataUpload_seasonal:message}
        });
                   
  }
}  

export function* checkTransactionWorker({ payload }) {
    try {
      const checkTran = yield call(axios.post, "/verify-transaction", payload);
      yield put({
        type: success(CHECK_TRANSACTION),
        payload: {
          checkTran: normalize(checkTran),
        },
      });
    } catch (e) {
      const parseError = yield JSON.parse(JSON.stringify(e));
      const message = parseError.data || parseError.response;
  
      yield put({
        type: error(CHECK_TRANSACTION),
        payload: { checkTranError: true, error: message },
      });
    }
  }

  export function* pushBackWebWorker({ payload }) {
    try {
        const {
            tran_id,
            amount,
            device,
            phone,
            schedule,
            userId,
            payment,
            detail
          }=payload;

        const newDate = moment(new Date()).format('YYYYMMDDHHmmss');
        const dataToHash = newDate + tran_id+amount+device+phone+schedule+userId+payment;
        const keyWordArray = CryptoJS.enc.Utf8.parse(HASH_KEY);
        const hmacSha512 = CryptoJS.HmacSHA512(dataToHash, keyWordArray);
        const base64EncodedHash = CryptoJS.enc.Base64.stringify(hmacSha512);
        const data={
            "tran_id":tran_id,
            "hash":base64EncodedHash,
            "device":device,
            "phone":phone,
            "amount":amount,
            "schedule":schedule,
            "id":userId,
            "payment":payment,
            "req_time":newDate,
            "detail":detail || ''
        }
        const pushBackWeb = yield call(axios.post,"/verify-code",data);
        yield put({
            type: success(WEB_PUSH_BACK),
            payload:{
                pushBackWeb:normalize(pushBackWeb)
            }});

    } catch (e) {
      pushBackTelegram(payload);
      yield put({
        type: error(WEB_PUSH_BACK),
        payload: {
            pushBackWeb: false,
        },
      });
    }
  }
// * REDIRECT
// export function* checkTransactionABAWorker({ payload }) {
// try {
//     const dataToHash = ABA_PAYWAY_MERCHANT_ID + payload?.codePay;
//     const keyWordArray = CryptoJS.enc.Utf8.parse(ABA_PAYWAY_KEY);
//     const hmacSha512 = CryptoJS.HmacSHA512(dataToHash, keyWordArray);
//     const base64EncodedHash = CryptoJS.enc.Base64.stringify(hmacSha512);
//     var data = new FormData();
//     data.append("hash", base64EncodedHash);
//     data.append("tran_id", payload?.codePay);
//     data.append("merchant_id", ABA_PAYWAY_MERCHANT_ID);
//     const checkTranABA = yield call(abaAxios.post,`${REACT_APP_ABA_PAYWAY_CHECK_TRANSACTION}`,data,{headers:{
//         "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
//         'Referer': REFERER,
//     }});
//     yield put({
//         type: success(CHECK_TRANSACTION_ABA),
//         payload:{
//             checkTranABA:normalize(checkTranABA)
//         }
//     });
// } catch (e) {
//     yield put({
//     type: error(CHECK_TRANSACTION_ABA),
//     payload: {
//         checkTranABA: false,
//     },
//     });
// }
// }

export function* checkTransactionABAWorker({ payload }) {
try {
    const checkTranABA = yield call(wingAxios.get,'https://inquiry.epstopikpayment.site/aba?id='+payload.codePay);
    yield put({
        type: success(CHECK_TRANSACTION_ABA),
        payload:{
            checkTranABA:normalize(checkTranABA)
        }
    });
} catch (e) {
    yield put({
    type: error(CHECK_TRANSACTION_ABA),
    payload: {
        checkTranABA: false,
    },
    });
}
}
export function* wingGenerateHashWorker({ payload }) {
try {
    const wingSKDToken = yield call(axios.post,"/wing-pay",payload);
    yield put({
        type: success(WING_GENERATE_HASH),
        payload:{
            wingHash:normalize(wingSKDToken)?.data,
        }
    });
} catch (e) {
    yield put({
    type: error(WING_GENERATE_HASH),
    payload: {
        wingHash: false,
    },
    });
}
} 
export function* verifyMemberSeasonalWorker({payload}){
    try {
        const verify_member_seasonal = yield call(axios.post,"/verify-member-seasonal",payload);
        yield put({
            type: success(VERIFY_MEMBER_SEASONAL),
            payload:{
                verify_member_seasonal:normalize(verify_member_seasonal),
                verify_member_seasonal_error:false
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e?.response));
        const message = parseError?.data;
        yield put({
                type:error(VERIFY_MEMBER_SEASONAL),
                payload:{verify_member_seasonal_error:message,error:message}
            })
    }
}
//* REDIRECT
// export function* wingTransactionWorker({ payload }) {
//     try {
//         const checkTranWING = yield call(wingAxios.post,REACT_APP_WING_ONLINE_INQUIRY,payload);
//         yield put({
//             type: success(CHECK_TRANSACTION_WING),
//             payload:{
//                 checkTranWING:normalize(checkTranWING),
//             }
//         });
//     } catch (e) {
//       yield put({
//         type: error(CHECK_TRANSACTION_WING),
//         payload: {
//             checkTranWING: false,
//         },
//       });
//     }
//   }

export function* wingTransactionWorker({ payload }) {
    try {
        const checkTranWING = yield call(wingAxios.get,'https://inquiry.epstopikpayment.site/wing?id='+payload.order_reference_no);
        yield put({
            type: success(CHECK_TRANSACTION_WING),
            payload:{
                checkTranWING:normalize(checkTranWING),
            }
        });
    } catch (e) {
      yield put({
        type: error(CHECK_TRANSACTION_WING),
        payload: {
            checkTranWING: false,
        },
      });
    }
  }
export function* homeSaga() {
    yield takeLatest(GET_JOB_TYPE, getTypeJobWorker);
    yield takeLatest(GET_JOB_TYPE_SEASONAL, getTypeJobSeasonalWorker);
    yield takeLatest(SUB_JOB, getSubjobWorker);
    yield takeLatest(SUBMIT_FORM, SubmitFormWorker);
    yield takeLatest(UPDATE_USER, updateUserWorker);
    yield takeLatest(VERIFY_BIRTHDATE, verifybirhtdateWorker);
    yield takeLatest(LINK_YOUTUBE, getLinkYoutubeWorker);
    yield takeLatest(PRICE, verifyPriceWorker);
    yield takeLatest(VERIFY_MEMBER, verifyMemberWorker);
    yield takeLatest(PAYMENT_SET, paymentSetWorker);
    yield takeLatest(VERIFY_PAY, paymentVerifyWorker);
    yield takeLatest(GET_YEARS, getYearsWorker);
    yield takeLatest(SUBMIT_FORM_TEMPOTERY, SubmitFormTempoteryWorker);
    yield takeLatest(CHECK_TRANSACTION, checkTransactionWorker);
    yield takeLatest(CHECK_TRANSACTION_ABA, checkTransactionABAWorker);
    yield takeLatest(WEB_PUSH_BACK, pushBackWebWorker);
    yield takeLatest(WING_GENERATE_HASH, wingGenerateHashWorker);
    yield takeLatest(CHECK_TRANSACTION_WING, wingTransactionWorker);
    yield takeLatest(VERIFY_MEMBER_SEASONAL, verifyMemberSeasonalWorker);
    yield takeLatest(SUBMIT_FORM_SEASONAL, SubmitFormSeasonalWorker);
    

}

  