import { call, put, takeLatest } from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import { axios } from "../app";
import {
    GET_MENU_ADMIN,
    ADD_MENU_ADMIN,
    UPDATE_MENU_ADMIN,
    DELETE_MENU_ADMIN,
    GET_MENU_ADMIN_PAGE,

    GET_USER,
    ADD_USER,
    UPDATE_USER_ADMIN,
    DELETE_USER,

    GET_USER_PERMISSION,
    UPDATE_USER_PERMISSION,

    GET_ACCOUNT_USER,
    COUNT_ACCOUNT_USER,
    UPDATE_ACCOUNT_USER,
    SEARCH_ACCOUNT_USER,
    DELETE_ACCOUNT_USER,

    GET_FORM,
    UPDATE_FORM,
    DELETE_FORM,
    ZIP_NOW,
    ZIP_CHECK,
    SEARCH_FORM,
    DOWNLOAD_DATA,
    FORM_COUNT,

    JOB_SUB_CATEGORY,
    JOB_CATEGORY,

    JOB_CATEGORY_SELECT,
    JOB_CATEGORY_INSERT,
    JOB_CATEGORY_UPDATE,

    SCHEDULE_EXAM_INSERT,
    SCHEDULE_EXAM_SELECT,
    SCHEDULE_EXAM_COUNT,
    SCHEDULE_EXAM_UPDATE,
    DELETE_DATA_TABLE,

    GET_YOUTUBE_VIDEO,
    UPDATE_YOUTUBE_VIDEO,

    GET_SENT_NOTIFICATION,
    SEND_NOTIFICATION,
    GET_COUNT_NOTIFICATION,
    DELETE_NOTIFICATION,

    GET_COUNT_MEMBERS,

    SPECIAL_MEMBERS_INSER,
    SPECIAL_MEMBERS_UPDATE,
    SPECIAL_MEMBERS_SELECT,
    SPECIAL_EXAM_SELECT,
    SPECIAL_EXAM_COUNT,

    GET_SEARCH_SUBMIT_MEMBER,
    INSERT_MEMBER,
    GET_SCHEDULE_EXAM,

    GET_VERSION_APP,
    UPDATE_VERSION_APP,

    GET_SCHEDULE_MEMBERS,
    GET_SCHEDULE_MEMBERS_DOWNLOAD,

    SCHEDULE_MEMBER_LOCKED_SELECT,
    SCHEDULE_MEMBER_LOCKED_INSERT,
    SCHEDULE_MEMBER_LOCKED_DELETE,
    SCHEDULE_MEMBER_LOCKED_UPDATE,

    TRANSACTIONS_SELECT

} from "./reducer";
import { normalize, normalizeData } from "../../utils/normalize"


export function* getMenuAdminWorker() {
    try {
        const menu_admin = yield call(axios.get, "/drawer");

        yield put({
            type: success(GET_MENU_ADMIN),
            payload: {
                menu_admin: normalizeData(menu_admin)
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(GET_MENU_ADMIN),
            payload: message.data ? message.data : message
        });
    }
}

export function* getMenuAdminPageWorker() {
    try {
        const menu_admin_page = yield call(axios.get, "/menu-admin/view");
        yield put({
            type: success(GET_MENU_ADMIN_PAGE),
            payload: {
                menu_admin_page: normalizeData(menu_admin_page)
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_MENU_ADMIN_PAGE),
            payload: {
                menu_admin: []
            }
        });
    }
}

export function* AddMenuAdminWorker({ payload }) {
    try {
        const add_menu_admin = yield call(axios.post, "/menu-admin/insert", payload);
        yield put({
            type: success(ADD_MENU_ADMIN),
            payload: {
                add_menu_admin: true,
                data: add_menu_admin
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(ADD_MENU_ADMIN),
            payload: message.data ? message.data : message
        });
    }
}

export function* updateMenuAdminWorker({ payload }) {
    try {
        const update_menu_admin = yield call(axios.post, "/menu-admin/update", payload);
        yield put({
            type: success(UPDATE_MENU_ADMIN),
            payload: {
                update_menu_admin: true,
                data: update_menu_admin
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;

        yield put({
            type: error(UPDATE_MENU_ADMIN),
            payload: message.data ? message.data : message
        });
    }
}

export function* deleteMenuAdminWorker({ payload }) {
    try {
        const delete_menu_admin = yield call(axios.post, "/menu-admin/delete", payload);
        yield put({
            type: success(DELETE_MENU_ADMIN),
            payload: {
                delete_menu_admin: true,
                data: delete_menu_admin
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(DELETE_MENU_ADMIN),
            payload: message.data ? message.data : message
        });
    }
}

export function* getUserWorker() {
    try {
        const user_list = yield call(axios.get, "/user-admin/view");
        yield put({
            type: success(GET_USER),
            payload: {
                user_list: normalizeData(user_list)
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_USER),
            payload: {
                user_list: []
            }
        });
    }
}

export function* addUserWorker({ payload }) {
    try {
        const add_user = yield call(axios.post, "/user-admin/insert", payload);
        yield put({
            type: success(ADD_USER),
            payload: {
                add_user: true,
                data: add_user
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(ADD_USER),
            payload: message.data ? message.data : message
        });
    }
}

export function* updateUserWorker({ payload }) {
    try {
        const update_user = yield call(axios.post, "/user-admin/update", payload);
        yield put({
            type: success(UPDATE_USER_ADMIN),
            payload: {
                update_user: true,
                data: update_user
            }
        });
    } catch (e) {
        yield put({
            type: error(UPDATE_USER_ADMIN),
            payload: { update_user: false }
        });
    }
}

export function* deleteUserWorker({ payload }) {
    try {
        const delete_user = yield call(axios.post, "/user-admin/delete", payload);
        yield put({
            type: success(DELETE_USER),
            payload: {
                delete_user: true,
                data: delete_user
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(DELETE_USER),
            payload: message.data ? message.data : message
        });
    }
}

export function* getUserPermissionWorker({ payload }) {
    try {
        const user_permission = yield call(axios.get, "/user-permission/view/" + payload);

        yield put({
            type: success(GET_USER_PERMISSION),
            payload: {
                user_permission: normalizeData(user_permission)
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_USER_PERMISSION),
            payload: {
                user_permission: []
            }
        });
    }
}

export function* updatePermissionWorker({ payload }) {
    try {
        const update_permission = yield call(axios.post, "/user-permission/update/", payload);
        yield put({
            type: success(UPDATE_USER_PERMISSION),
            payload: {
                update_permission: true,
                data: update_permission
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(UPDATE_USER_PERMISSION),
            payload: message.data ? message.data : message
        });
    }
}


export function* getAccountUserWorker({ payload }) {
    try {
        const user_accound_list = yield call(axios.get, "account-user/view/" + payload.page + "/" + payload.special);
        yield put({
            type: success(GET_ACCOUNT_USER),
            payload: {
                user_accound_list: normalizeData(user_accound_list)
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_ACCOUNT_USER),
            payload: {
                user_accound_list: []
            }
        });
    }
}
export function* getAccountUserCountWorker({ payload }) {
    try {
        const account_user_count = yield call(axios.get, "/account-user/count/" + payload);
        yield put({
            type: success(COUNT_ACCOUNT_USER),
            payload: {
                account_user_count: account_user_count.data.results[0]['count']
            }
        });
    } catch (e) {
        yield put({
            type: error(COUNT_ACCOUNT_USER),
            payload: {
                account_user_count: 0
            }
        });
    }
}
export function* searchAccountUserWorker({ payload }) {
    try {

        const search_user_accound_list = yield call(axios.post, "/account-user/view/search", payload);
        yield put({
            type: success(SEARCH_ACCOUNT_USER),
            payload: {
                search_user_accound_list: search_user_accound_list.data.data
            }
        });
    } catch (e) {
        yield put({
            type: error(SEARCH_ACCOUNT_USER),
            payload: {
                search_user_accound_list: []
            }
        });
    }
}
export function* updateAccountUserWorker({ payload }) {
    try {
        const update_account_user = yield call(axios.post, "/account-user/update", payload);
        yield put({
            type: success(UPDATE_ACCOUNT_USER),
            payload: {
                update_account_user: true,
                data: update_account_user
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(UPDATE_ACCOUNT_USER),
            payload: message.data ? message.data : message
        });
    }
}
export function* deleteAccountUserWorker({ payload }) {
    try {
        const delete_account_user = yield call(axios.post, "/account-user/delete", payload);
        yield put({
            type: success(DELETE_ACCOUNT_USER),
            payload: {
                delete_account_user: true,
                data: delete_account_user
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(DELETE_ACCOUNT_USER),
            payload: message.data ? message.data : message
        });
    }
}


export function* getFormWorker({ payload }) {
    try {
        const form_list = yield call(axios.get, "/user-form/view/" + payload.page + "/" + payload.special);
        yield put({
            type: success(GET_FORM),
            payload: {
                form_list: normalizeData(form_list)
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_FORM),
            payload: {
                form_list: []
            }
        });
    }
}
export function* getFormCountWorker() {
    try {
        const form_count = yield call(axios.get, "/user-form/count");
        yield put({
            type: success(FORM_COUNT),
            payload: {
                form_count: form_count.data.results[0]['count']
            }
        });
    } catch (e) {
        yield put({
            type: error(FORM_COUNT),
            payload: {
                form_count: []
            }
        });
    }
}
export function* searchFormWorker({ payload }) {
    try {
        const search_form_list = yield call(axios.post, "/user-form/search", payload);
        yield put({
            type: success(SEARCH_FORM),
            payload: {
                search_form_list: search_form_list.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(SEARCH_FORM),
            payload: {
                search_form_list: []
            }
        });
    }
}

export function* downloadDataWorker({ payload }) {
    try {
        const download_data_list = yield call(axios.get, "/user-form/downlaod-data/" + payload);
        yield put({
            type: success(DOWNLOAD_DATA),
            payload: {
                download_data_list: download_data_list.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(DOWNLOAD_DATA),
            payload: {
                download_data_list: []
            }
        });
    }
}


export function* getZipFormWorker({ payload }) {
    try {
        const zip_file = yield call(axios.get, "/user-form/zip/" + payload);
        yield put({
            type: success(ZIP_NOW),
            payload: {
                zip_file: normalize(zip_file)
            }
        });
    } catch (e) {
        yield put({
            type: error(ZIP_NOW),
            payload: {
                zip_file: false
            }
        });
    }
}

export function* getCheckZipFormWorker({ payload }) {
    try {
        const zip_check_file = yield call(axios.get, "/user-form/check-zip/" + payload);
        yield put({
            type: success(ZIP_CHECK),
            payload: {
                zip_check_file: normalize(zip_check_file)
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(ZIP_CHECK),
            payload: {
                zip_check_file: false,
                zip_check_file_error: message
            }
        });
    }
}

export function* updateFormWorker({ payload }) {
    try {
        const update_form = yield call(axios.post, "/user-form/update", payload);
        yield put({
            type: success(UPDATE_FORM),
            payload: {
                update_form: true,
                data: update_form
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(UPDATE_FORM),
            payload: message.data ? message.data : message
        });
    }
}

export function* deleteFormWorker({ payload }) {
    try {
        const delete_form = yield call(axios.post, "/user-form/delete", payload);
        yield put({
            type: success(DELETE_FORM),
            payload: {
                delete_form: delete_form
            }
        });
    } catch (e) {
        yield put({
            type: error(DELETE_FORM),
            payload: { delete_form: "error" }
        });
    }
}

export function* getJobCategoryWorker() {
    try {

        const job_list = yield call(axios.get, "/job-categories/view/main_job");
        yield put({
            type: success(JOB_CATEGORY),
            payload: {
                job_list: job_list.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(JOB_CATEGORY),
            payload: {
                job_list: []
            }
        });
    }
}
export function* getJobSubCategoryWorker({ payload }) {
    try {
        const job_sub_list = yield call(axios.get, "/job-categories/secorndery_job/" + payload);
        yield put({
            type: success(JOB_SUB_CATEGORY),
            payload: {
                job_sub_list: job_sub_list.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(JOB_SUB_CATEGORY),
            payload: {
                job_sub_list: []
            }
        });
    }
}

export function* getJobCategoriesWorker({ payload }) {
    try {
        const select_job_categories = yield call(axios.get, "/job-categories/view/" + payload);
        yield put({
            type: success(JOB_CATEGORY_SELECT),
            payload: {
                select_job_categories: select_job_categories.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(JOB_CATEGORY_SELECT),
            payload: {
                select_job_categories: []
            }
        });
    }
}
export function* setJobCategoriesWorker({ payload }) {
    try {
        const insert_job_categories = yield call(axios.post, "/job-categories/insert", payload);
        yield put({
            type: success(JOB_CATEGORY_INSERT),
            payload: {
                insert_job_categories: true,
                data: insert_job_categories
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(JOB_CATEGORY_INSERT),
            payload: message.data ? message.data : message
        });
    }
}
export function* updateJobCategoriesWorker({ payload }) {
    try {
        const update_job_categories = yield call(axios.post, "/job-categories/update", payload);
        yield put({
            type: success(JOB_CATEGORY_INSERT),
            payload: {
                update_job_categories: true,
                data: update_job_categories
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(JOB_CATEGORY_INSERT),
            payload: message.data ? message.data : message
        });
    }
}
//---------- No delete job
export function* getScheduleWorker({ payload }) {
    try {
        const select_schedule_exam = yield call(axios.get, "/schedule-exam/view/" + payload.page + "/" + payload.special);
        yield put({
            type: success(SCHEDULE_EXAM_SELECT),
            payload: {
                select_schedule_exam: select_schedule_exam.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(SCHEDULE_EXAM_SELECT),
            payload: {
                select_schedule_exam: []
            }
        });
    }
}
export function* getScheduleCountWorker({ payload }) {
    try {
        const count_schedule_exam = yield call(axios.get, "/schedule-exam/count/" + payload);
        yield put({
            type: success(SCHEDULE_EXAM_COUNT),
            payload: {
                count_schedule_exam: count_schedule_exam.data.results[0]['count']
            }
        });
    } catch (e) {
        yield put({
            type: error(SCHEDULE_EXAM_COUNT),
            payload: {
                select_schedule_exam: []
            }
        });
    }
}

export function* setScheduleWorker({ payload }) {
    try {
        const insert_schedule_exam = yield call(axios.post, "/schedule-exam/insert", payload);
        yield put({
            type: success(SCHEDULE_EXAM_INSERT),
            payload: {
                insert_schedule_exam: true,
                data: insert_schedule_exam
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        alert('create schedule not Complete!')
        yield put({
            type: error(SCHEDULE_EXAM_INSERT),
            payload: message.data ? message.data : message
        });
    }
}
export function* updateScheduleWorker({ payload }) {
    try {
        const update_schedule_exam = yield call(axios.post, "/schedule-exam/update", payload);
        yield put({
            type: success(SCHEDULE_EXAM_UPDATE),
            payload: {
                update_schedule_exam: true,
                data: update_schedule_exam
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(SCHEDULE_EXAM_UPDATE),
            payload: message.data ? message.data : message
        });
    }
}
export function* deleteDataTableWorker({ payload }) {
    try {
        const delete_data_table = yield call(axios.post, "/schedule-exam/downlaod-delete", payload);
        yield put({
            type: success(DELETE_DATA_TABLE),
            payload: {
                delete_data_table: true,
                data: delete_data_table
            }
        });
    } catch (e) {
        yield put({
            type: error(DELETE_DATA_TABLE),
            payload: { delete_data_table: false }
        });
    }
}

export function* getyoutubeWorker() {
    try {
        const get_video_youtube = yield call(axios.get, "/video-youtube/view");
        yield put({
            type: success(GET_YOUTUBE_VIDEO),
            payload: {
                get_video_youtube: get_video_youtube.data.data
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_YOUTUBE_VIDEO),
            payload: { get_video_youtube: [] }
        });
    }
}
export function* updateyoutubeWorker({ payload }) {
    try {
        const update_video_youtube = yield call(axios.post, "/video-youtube/update", payload);
        yield put({
            type: success(UPDATE_YOUTUBE_VIDEO),
            payload: {
                update_video_youtube: true,
                data: update_video_youtube
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(UPDATE_YOUTUBE_VIDEO),
            payload: message.data ? message.data : message
        });
    }
}
export function* getsentnotificationWorker({ payload }) {
    try {
        const get_send_notification = yield call(axios.get, "/send_notification/view/" + payload);
        yield put({
            type: success(GET_SENT_NOTIFICATION),
            payload: {
                get_send_notification: get_send_notification.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_SENT_NOTIFICATION),
            payload: { get_send_notification: [] }
        });
    }
}
export function* getDeletenotificationWorker({ payload }) {
    try {
        const delete_notification = yield call(axios.post, "/send_notification/delete", payload);
        yield put({
            type: success(DELETE_NOTIFICATION),
            payload: {
                delete_notification: true,
                data: delete_notification
            }
        });
    } catch (e) {
        yield put({
            type: error(DELETE_NOTIFICATION),
            payload: { delete_notification: 'error' }
        });
    }
}
export function* getCountNotificationWorker() {
    try {
        const get_count_notification = yield call(axios.get, "/send_notification/count");
        yield put({
            type: success(GET_COUNT_NOTIFICATION),
            payload: {
                get_count_notification: get_count_notification.data.results[0]['count']
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_COUNT_NOTIFICATION),
            payload: { get_count_notification: 0 }
        });
    }
}
export function* sendNotificationWorker({ payload }) {
    try {
        const send_notification = yield call(axios.post, "/send_notification/insert", payload);
        yield put({
            type: success(SEND_NOTIFICATION),
            payload: {
                send_notification: true,
                data: send_notification
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(SEND_NOTIFICATION),
            payload: message.data ? message.data : message
        });
    }
}

export function* getCountMemberWorker({ payload }) {
    try {
        const get_count_members = yield call(axios.get, "/count-members");
        yield put({
            type: success(GET_COUNT_MEMBERS),
            payload: {
                get_count_members: get_count_members.data.data
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_COUNT_MEMBERS),
            payload: { get_count_members: [] }
        });
    }
}
export function* getSpecialExam() {
    try {
        const special_exam = yield call(axios.get, "/special_members/view/exam");
        yield put({
            type: success(SPECIAL_EXAM_SELECT),
            payload: {
                special_exam: special_exam.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(SPECIAL_EXAM_SELECT),
            payload: {
                special_exam: []
            }
        });
    }
}

export function* getSelectSpecialMembers({ payload }) {
    try {
        const select_special_members = yield call(axios.get, "/special_members/view/" + payload['id'] + "/" + payload['page']);
        yield put({
            type: success(SPECIAL_MEMBERS_SELECT),
            payload: {
                select_special_members: select_special_members.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(SPECIAL_MEMBERS_SELECT),
            payload: {
                select_special_members: []
            }
        });
    }
}
export function* getInsertSpecialMembers({ payload }) {
    try {
        const insert_special_members = yield call(axios.post, "/special_members/insert", payload);
        yield put({
            type: success(SPECIAL_MEMBERS_INSER),
            payload: {
                insert_special_members: true,
                data: insert_special_members
            }
        });
    } catch (e) {
        yield put({
            type: error(SPECIAL_MEMBERS_INSER),
            payload: {
                insert_special_members: false
            }
        });
    }
}
export function* getCountSpecialMembers({ payload }) {
    try {
        const count_special_members = yield call(axios.get, "/special_members/count/" + payload['id']);
        yield put({
            type: success(SPECIAL_EXAM_COUNT),
            payload: {
                count_special_members: count_special_members.data.results[0]['count']
            }
        });
    } catch (e) {
        yield put({
            type: error(SPECIAL_EXAM_COUNT),
            payload: {
                count_special_members: false
            }
        });
    }
}
export function* getUpdateSpecialMembers({ payload }) {
    try {
        const update_special_members = yield call(axios.post, "/special_members/update", payload);
        yield put({
            type: success(SPECIAL_MEMBERS_UPDATE),
            payload: {
                update_special_members: true,
                data: update_special_members
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(SPECIAL_MEMBERS_UPDATE),
            payload: message.data ? message.data : message
        });
    }
}

export function* getsearchSubmitMembers({ payload }) {
    try {
        const get_search_submit_member = yield call(axios.post, "/submit_members/view", payload);
        yield put({
            type: success(GET_SEARCH_SUBMIT_MEMBER),
            payload: {
                get_search_submit_member: get_search_submit_member
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_SEARCH_SUBMIT_MEMBER),
            payload: {
                get_search_submit_member: false
            }
        });
    }
}

export function* getinsertSubmitMembers({ payload }) {
    try {
        const form = new FormData();
        for (const key in payload) {
            const element = payload[key];
            form.append(key, element)
        }
        const insert_member = yield call(axios.post, "/submit_members/insert", form);
        const requested = normalize(insert_member);
        yield put({
            type: success(INSERT_MEMBER),
            payload: {
                insert_member: requested
            }
        })
    } catch (e) {
        yield put({
            type: error(INSERT_MEMBER),
            payload: {
                error_insert_member: true,
                insert_member: false
            }
        })
    }
}

export function* getshceduleExamSubmitMembers() {
    try {
        const get_schedule_submit_member = yield call(axios.get, "/submit_members/view/schedules");
        yield put({
            type: success(GET_SCHEDULE_EXAM),
            payload: {
                get_schedule_submit_member: get_schedule_submit_member
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_SCHEDULE_EXAM),
            payload: {
                get_schedule_submit_member: false
            }
        });
    }
}


export function* getVersionAppWorker() {
    try {
        const get_version_app = yield call(axios.get, "/version-app/view");
        yield put({
            type: success(GET_VERSION_APP),
            payload: {
                get_version_app: get_version_app.data.data
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_VERSION_APP),
            payload: { get_version_app: [] }
        });
    }
}
export function* updateVersionAppWorker({ payload }) {
    try {
        const update_version_app = yield call(axios.post, "/version-app/update", payload);
        yield put({
            type: success(UPDATE_VERSION_APP),
            payload: {
                update_version_app: true,
                data: update_version_app
            }
        });
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError;
        yield put({
            type: error(UPDATE_VERSION_APP),
            payload: message.data ? message.data : message
        });
    }
}

export function* getScheduleMembersWorker({ payload }) {
    try {
        const get_schedule_members = yield call(axios.post, "/schedule_members/view", payload);
        yield put({
            type: success(GET_SCHEDULE_MEMBERS),
            payload: {
                get_schedule_members: get_schedule_members.data.data,
                get_schedule_members_total: get_schedule_members.data.total
            }
        });
    } catch (e) {
        yield put({
            type: error(GET_SCHEDULE_MEMBERS),
            payload: { get_schedule_members: [], get_schedule_members_total: 0 }
        });
    }
}
export function* getScheduleMembersDownloadWorker({ payload }) {
    try {
        const get_schedule_members_download = yield call(axios.post, "/schedule_members/download", payload);
        const arrBufferAgain = new Int32Array(get_schedule_members_download.data).buffer;
        const blob = new Blob([arrBufferAgain], {
            type: "application/octet-stream",
        });

        var csvURL = URL.createObjectURL(blob);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'filename.zip');
        tempLink.click();
        return URL.createObjectURL(blob);
    } catch (e) {
        yield put({
            type: error(GET_SCHEDULE_MEMBERS_DOWNLOAD),
            payload: { get_schedule_members_download: [] }
        });
    }
}

export function* getScheduleMemberLockedWorker({ payload }) {
    try {
        const schedule_members_locked = yield call(axios.get, "/schedule-members-locked/view/" + payload);
        yield put({
            type: success(SCHEDULE_MEMBER_LOCKED_SELECT),
            payload: {
                schedule_members_locked: schedule_members_locked.data.results
            }
        });
    } catch (e) {
        yield put({
            type: error(SCHEDULE_MEMBER_LOCKED_SELECT),
            payload: {
                schedule_members_locked: []
            }
        });
    }
}

export function* insertScheduleMemberLockedWorker({ payload }) {
    try {
        const insert_schedule_members_locked = yield call(axios.post, "/schedule-members-locked/insert", payload);
        yield put({
            type: success(SCHEDULE_MEMBER_LOCKED_INSERT),
            payload: {
                insert_schedule_members_locked: insert_schedule_members_locked
            }
        });
    } catch (e) {
        yield put({
            type: error(SCHEDULE_MEMBER_LOCKED_INSERT),
            payload: { insert_schedule_members_locked: "error" }
        });
    }
}

export function* updateScheduleMemberLockedWorker({ payload }) {
    try {
        const update_schedule_members_locked = yield call(axios.post, "/schedule-members-locked/update", payload);
        yield put({
            type: success(SCHEDULE_MEMBER_LOCKED_UPDATE),
            payload: {
                update_schedule_members_locked: update_schedule_members_locked
            }
        });
    } catch (e) {
        yield put({
            type: error(SCHEDULE_MEMBER_LOCKED_UPDATE),
            payload: { update_schedule_members_locked: "error" }
        });
    }
}

export function* deleteScheduleMemberLockedWorker({ payload }) {
    try {
        const delete_schedule_members_locked = yield call(axios.post, "/schedule-members-locked/delete", payload);
        yield put({
            type: success(SCHEDULE_MEMBER_LOCKED_DELETE),
            payload: {
                delete_schedule_members_locked: delete_schedule_members_locked
            }
        });
    } catch (e) {
        yield put({
            type: error(SCHEDULE_MEMBER_LOCKED_DELETE),
            payload: { delete_schedule_members_locked: "error" }
        });
    }
}

export function* getTransactionsSelect({ payload }) {
    try {
        const select_transaction = yield call(axios.post, "/transactions/view", payload);
        yield put({
            type: success(TRANSACTIONS_SELECT),
            payload: {
                select_transaction: select_transaction.data.data,
                select_transaction_total: select_transaction.data.total
            }
        });
    } catch (e) {
        yield put({
            type: error(TRANSACTIONS_SELECT),
            payload: { select_transaction: [], select_transaction_total: 0 }
        });
    }
}
export function* dashboardSaga() {
    yield takeLatest(GET_MENU_ADMIN, getMenuAdminWorker);
    yield takeLatest(ADD_MENU_ADMIN, AddMenuAdminWorker)
    yield takeLatest(UPDATE_MENU_ADMIN, updateMenuAdminWorker)
    yield takeLatest(DELETE_MENU_ADMIN, deleteMenuAdminWorker)
    yield takeLatest(GET_MENU_ADMIN_PAGE, getMenuAdminPageWorker)

    yield takeLatest(GET_USER, getUserWorker)
    yield takeLatest(ADD_USER, addUserWorker)
    yield takeLatest(UPDATE_USER_ADMIN, updateUserWorker)
    yield takeLatest(DELETE_USER, deleteUserWorker)

    yield takeLatest(GET_USER_PERMISSION, getUserPermissionWorker)
    yield takeLatest(UPDATE_USER_PERMISSION, updatePermissionWorker)

    yield takeLatest(GET_ACCOUNT_USER, getAccountUserWorker)
    yield takeLatest(UPDATE_ACCOUNT_USER, updateAccountUserWorker)
    yield takeLatest(DELETE_ACCOUNT_USER, deleteAccountUserWorker)
    yield takeLatest(SEARCH_ACCOUNT_USER, searchAccountUserWorker)
    yield takeLatest(COUNT_ACCOUNT_USER, getAccountUserCountWorker)


    yield takeLatest(GET_FORM, getFormWorker)
    yield takeLatest(UPDATE_FORM, updateFormWorker)
    yield takeLatest(DELETE_FORM, deleteFormWorker)
    yield takeLatest(ZIP_NOW, getZipFormWorker)
    yield takeLatest(ZIP_CHECK, getCheckZipFormWorker)
    yield takeLatest(SEARCH_FORM, searchFormWorker)
    yield takeLatest(DOWNLOAD_DATA, downloadDataWorker)
    yield takeLatest(FORM_COUNT, getFormCountWorker)


    yield takeLatest(JOB_CATEGORY, getJobCategoryWorker)
    yield takeLatest(JOB_SUB_CATEGORY, getJobSubCategoryWorker)

    yield takeLatest(JOB_CATEGORY_SELECT, getJobCategoriesWorker)
    yield takeLatest(JOB_CATEGORY_INSERT, setJobCategoriesWorker)
    yield takeLatest(JOB_CATEGORY_UPDATE, updateJobCategoriesWorker)

    yield takeLatest(SCHEDULE_EXAM_SELECT, getScheduleWorker)
    yield takeLatest(SCHEDULE_EXAM_INSERT, setScheduleWorker)
    yield takeLatest(SCHEDULE_EXAM_UPDATE, updateScheduleWorker)
    yield takeLatest(SCHEDULE_EXAM_COUNT, getScheduleCountWorker)


    yield takeLatest(DELETE_DATA_TABLE, deleteDataTableWorker)

    yield takeLatest(GET_YOUTUBE_VIDEO, getyoutubeWorker)
    yield takeLatest(UPDATE_YOUTUBE_VIDEO, updateyoutubeWorker)

    yield takeLatest(GET_SENT_NOTIFICATION, getsentnotificationWorker)
    yield takeLatest(SEND_NOTIFICATION, sendNotificationWorker)
    yield takeLatest(GET_COUNT_NOTIFICATION, getCountNotificationWorker)
    yield takeLatest(DELETE_NOTIFICATION, getDeletenotificationWorker)

    yield takeLatest(GET_COUNT_MEMBERS, getCountMemberWorker)

    yield takeLatest(SPECIAL_EXAM_SELECT, getSpecialExam)
    yield takeLatest(SPECIAL_MEMBERS_SELECT, getSelectSpecialMembers)
    yield takeLatest(SPECIAL_MEMBERS_UPDATE, getUpdateSpecialMembers)
    yield takeLatest(SPECIAL_MEMBERS_INSER, getInsertSpecialMembers)
    yield takeLatest(SPECIAL_EXAM_COUNT, getCountSpecialMembers)

    yield takeLatest(GET_SEARCH_SUBMIT_MEMBER, getsearchSubmitMembers)
    yield takeLatest(INSERT_MEMBER, getinsertSubmitMembers)
    yield takeLatest(GET_SCHEDULE_EXAM, getshceduleExamSubmitMembers)

    yield takeLatest(GET_VERSION_APP, getVersionAppWorker)
    yield takeLatest(UPDATE_VERSION_APP, updateVersionAppWorker)

    yield takeLatest(GET_SCHEDULE_MEMBERS, getScheduleMembersWorker)
    yield takeLatest(GET_SCHEDULE_MEMBERS_DOWNLOAD, getScheduleMembersDownloadWorker)

    yield takeLatest(SCHEDULE_MEMBER_LOCKED_SELECT, getScheduleMemberLockedWorker)
    yield takeLatest(SCHEDULE_MEMBER_LOCKED_INSERT, insertScheduleMemberLockedWorker)
    yield takeLatest(SCHEDULE_MEMBER_LOCKED_UPDATE, updateScheduleMemberLockedWorker)
    yield takeLatest(SCHEDULE_MEMBER_LOCKED_DELETE, deleteScheduleMemberLockedWorker)

    yield takeLatest(TRANSACTIONS_SELECT, getTransactionsSelect)

}
