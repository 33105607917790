import { connect } from "react-redux";
import ScreenHome from "../screen/ScreenForm"
import {
      get_type_job_f,
      get_sub_job_f,
      submit_form_f,
      update_user_f,
      verify_birthdate_f,
      verify_member_f,
      payment_set_f,
      payment_verify_f,
      get_years_f,
      submit_form_tempotery_f,
      verify_price_f,
      web_push_back_f
      ,wing_generate_hash_f,
    } from '../modules/home/reducer'
import {get_code_exam_f} from '../modules/conditions/reducer'
import {profile_f} from '../modules/profile/reducer'
const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = {
  get_type_job_f,
  get_sub_job_f,
  submit_form_f,
  get_code_exam_f,
  // payment_f,
  update_user_f,
  verify_birthdate_f,
  verify_price_f,
  verify_member_f,
  payment_set_f,
  payment_verify_f,
  profile_f,
  get_years_f,

  submit_form_tempotery_f,
  web_push_back_f,
  wing_generate_hash_f,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenHome);
