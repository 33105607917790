import { payment_verify_f, CHECK_TRANSACTION,CHECK_TRANSACTION_ABA,CHECK_TRANSACTION_WING } from '../modules/home/reducer';

import store from '../store/index';
const REACT_APP_WING_USERNAME = process.env.REACT_APP_WING_USERNAME;
const REACT_APP_WING_REST = process.env.REACT_APP_WING_REST;

var checking = false;
var interval_FunABA = false;
var interval_FunBAKONG = false;
var interval_FunWING = false;

export const reqVerifyPayment = (tran_id, paymentType, cancel = null) => {
   checking = setInterval(() => {
      store.dispatch(payment_verify_f({ codePay: tran_id, payment: paymentType }))
   }, 5000);
   setTimeout(() => {
      cancelVerifyPayment();
      if (cancel) {
         cancel();
      }
   }, 120000)
}

export const cancelVerifyPayment = () => {
   if (checking) {
      clearInterval(checking);
      checking = false;
   }
}

export const reqVerifyPayment_3s = (tran_id) => {
   checking = setInterval(() => {
      store.dispatch(payment_verify_f({ codePay: tran_id }))
   }, 3000);
   setTimeout(() => {
      cancelVerifyPayment();
   }, 9000)
}


export const verifyTransactionABA = (tran_id, paymentType) => {
   try {
      interval_FunABA = setInterval(() => {
         store.dispatch({
           type: CHECK_TRANSACTION_ABA,
           payload: { codePay: tran_id, payment: paymentType },
         });
      }, 8000);
   } catch (e) {
      clearInterval(interval_FunABA);
   }
}

export const cancelCerifyTransactionABA = () => {
   if (interval_FunABA) {
      localStorage.removeItem('paymentTran');
      clearInterval(interval_FunABA);
      interval_FunABA = false;
   }
}

export const verifyTransactionBAKONG = (tran_id, paymentType) => {
   try {
      interval_FunBAKONG = setInterval(() => {
         store.dispatch({ type: CHECK_TRANSACTION, payload: { codePay: tran_id, payment: paymentType } });
      }, 5000);
   } catch (e) {
      clearInterval(interval_FunBAKONG);
   }
}
export const cancelCerifyTransactionBAKONG = () => {
   if (interval_FunBAKONG) {
      localStorage.removeItem('paymentTran');

      clearInterval(interval_FunBAKONG);
      interval_FunBAKONG = false;
   }
}

export const verifyTransactionWING = (tran_id) => {
   try {
      interval_FunWING = setInterval(() => {
         store.dispatch({ type: CHECK_TRANSACTION_WING, payload: { 
         "username": REACT_APP_WING_USERNAME,
         "rest_api_key": REACT_APP_WING_REST,
         "order_reference_no": tran_id, 
         "sandbox":"0" } });
      }, 8000);
   } catch (e) {
      clearInterval(interval_FunWING);
   }
}
export const cancelCerifyTransactionWING = () => {
   if (interval_FunWING) {
      localStorage.removeItem('paymentTran');
      clearInterval(interval_FunWING);
      interval_FunWING = false;
   }
}