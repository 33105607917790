import { call, put, takeLatest } from "redux-saga/effects";
import { error, success } from "redux-saga-requests";
import {axios} from "../app/index";
import {normalize} from "../../utils/normalize";

import {
    PAYMENT,
} from './reducer'

export function* paymentWorker({payload}){
    try {
        const payment_ = yield call(axios.post,"/payment",payload);
        const requested = normalize(payment_);
        yield put({
            type: success(PAYMENT),
            payload:{
                payment_:requested
            }
        })
    } catch (e) {
        const parseError = yield JSON.parse(JSON.stringify(e));
        const message = parseError.data || parseError.response
        yield put({
            type:error(PAYMENT),
            payload:{errorGetWithdrawFee:message}
        })
    }
}
export function* paymentSaga(){
    yield takeLatest(PAYMENT,paymentWorker);

}