/* eslint-disable no-useless-escape */
import React from 'react';
import { IMAGE } from '../assets/image';
import Header from '../components/header';
import { COLORMESSAGE } from '../assets/color'
import { Redirect } from 'react-router-dom'
import { Dropdown, Form, Button } from 'react-bootstrap'
import '../styles/ScreenForm.css';
import moment from 'moment'
import { findItem, findMonth, findGender } from '../utils/findItem'
import AlertMessage from '../components/alertMessage';
import { GENDER, MONTH } from '../assets/fixData'
import Loading from '../components/loading';
import MyLoadingPaymentSet from '../components/MyLoadingPaymentSet'
import { store } from '../store/index'
import { Row, Col } from 'react-bootstrap';
import {APP_SCHEDULE} from '../modules/app/reducer';
import { 
  cancelCerifyTransactionWING,
  cancelCerifyTransactionBAKONG,
  verifyTransactionABA,
  cancelCerifyTransactionABA,
  verifyTransactionBAKONG,
  verifyTransactionWING,
} from '../utils/reqVerifyPayment';
import $ from "jquery";
import ModalPreview from '../components/ModalPreview'
import { trimEmptyString,isValidPhoneNumber } from '../utils';
import CryptoJS from 'crypto-js';
import Iframe from 'react-iframe';

const ABA_API_PURCHASE=process.env.REACT_APP_ABA_API_PURCHASE;
const WING_ONLINE_URL=process.env.REACT_APP_WING_ONLINE_URL;
const ABA_PAYWAY_KEY=process.env.REACT_APP_ABA_PAYWAY_KEY;
export default class ScreenLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      gender: '',
      birthday:'',
      day: '',
      months: 1,
      year: moment().dayOfYear(),
      showDay: '',
      showMonth: '',
      showYear: '',
      showJob: '',
      showSubjob: '',
      tran_id:'',

      jobs: [],
      imageInde: null,
      imageProfile: null,
      typePayment: null,

      phonenumber: '',
      numberID: '',

      showMess: false,
      error: false,
      success: false,

      comfirmurInfo: false,
      pysical: false,
      experince: false,

      showProfile: null,
      showID: null,
      paynow: false,
      modalWing: false,
      urlEncodedData: "",
      endDate: 31,
      codePay: '',

      schedule_exam: {
        aba: 0,
        bakong:0,
        dateEnd:"",
        dateStart: "",
        folder: "",
        id: 0,
        maxBirthday:"",
        minBirthday:"",
        name:"",
        open:0,
        price:28,
        special:0,
        status:0,
        wing:0
      },
      paymentLoading: false,
      redirect: {
        page: "",
        action: false
      },
      openPreview: false,
      dataPreviewed: null,
      showTitleCate: '',
      showTitleJob: '',
      paymentData: {
        hash: '',
        firstname: '',
        amount: '',
        phone: '',
        tran_id: '',
        req_time: '',
        merchant_id: '',
        payment_option: ''
      },
      abaErrorCount:0,
      wingPayment:null,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeProfile = this.handleChangeProfile.bind(this);
  }
  handleBeforeUnload() {
    cancelCerifyTransactionABA();
  }
  componentDidMount() {
    // if have old tran_id it check 15s
    this.handleCheckTranIdStartup();
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    store.dispatch({ type: APP_SCHEDULE });
    //login user or not
    if (!this.props.user || !this.props.user.login || this.props.user.login.message !== "success") {
      this.setState({
        redirect: {
          page: "/",
          action: true
        }
      })
    }
    //register already or not
    if (this.props.profile.profile_ && this.props.profile.profile_.message === 'success') {
      this.setState({
        redirect: {
          page: "/result",
          action: true
        }
      })
    }
    //restart value aba pay
    this.setState({
      paymentData: {
        hash: '',
        firstname: '',
        amount: '',
        phone: '',
        tran_id: '',
        req_time: '',
        merchant_id: '',
        payment_option: ''
      }
    });

    $(document).ready(function () {
      $('#checkout_button').click(function () {
        $('#aba_merchant_request').append($(".payment_option:checked"));
        window.actionPayAba();
      });
    });
  }
  handleCrateTransaction() {
    $("#checkout_button").trigger("click");
    this.setState({paymentLoading: false,});
  }
  componentWillUpdate(nextProps, nextState) {
    const { jobs, schedule_exam, } = this.state;
    if (nextState.schedule_exam.id && nextState.schedule_exam.id !== schedule_exam.id && nextState.schedule_exam.id>0) {
      if (jobs.length > 0) {

      } else {
        this.props.get_type_job_f(`${nextState.schedule_exam.special}/${nextState.schedule_exam.folder}`);
        this.props.profile_f({schedule:nextState.schedule_exam.id});
      }
    }
  }
  PaymentViaWing = async (wingData) => {
   this.props.wing_generate_hash_f(wingData);
  }
  async componentWillReceiveProps(NextProps) {
    const { home, profile, payment, app } = this.props;
    try {
      if (NextProps.app.schedule_exam.message === 'success') {
        const data=NextProps?.app?.schedule_exam?.['data']?.[0] || null;
        if(data)
        this.setState({
          schedule_exam: {...data}
        });
      }
      if (NextProps.home.type_job && NextProps.home.type_job !== home.type_job) {
        if (NextProps.home.type_job.message === 'success') {
          if (NextProps.home.type_job.data.length > 0 && this.state.jobs.length === 0) {
            this.setState({
              jobs:NextProps?.home?.type_job?.data || []
            });
          }
        }
      }
      
      if (NextProps.payment.payment_ && NextProps.payment.payment_ !== payment.payment_) {
        if (NextProps.payment.payment_.message === "success") {
          this.setState({
            showMess: 'ការចុះឈ្មោះបានជោគជ័យ!',
            success: true,
            paynow: false,
            modalWing: false
          });
          this.props.update_user_f();
        }
      }
     
      // verify member before submit
      if (NextProps.home.verify_member_error && NextProps.home.verify_member_error !== home.verify_member_error) {
        if (NextProps.home.verify_member_error.message === 'exist-member-in-schedule') {
          this.setState({
            showMess: `សូមអភ័យទោស​ បេក្ខជន${this.state.name}មិនអាចស្នើសុំពាក្យធ្វើតេស្តជាលើកទីពីរបានទេ!`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          });
        }else if (NextProps.home.verify_member_error.message === 'not-exist-member-in-special-schedule') {
          this.setState({
            showMess: `សូមអភ័យទោស​ បេក្ខជន${this.state.name}មិនអាចស្នើសុំពាក្យធ្វើតេស្តវគ្គពិសេសបានទេ!`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          });
        }else if (NextProps.home.verify_member_error.message === 'member-is-blocked') {
          this.setState({
            showMess: `ទិន្នន័យរបស់អ្នកកំពុងជួបបញ្ហាក្នុងការស្នើសុំប្រឡងតេស្តសមត្ថភាព!`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          });
         
        }else if (NextProps.home.verify_member_error.message === 'not-exist-schedule') {
          this.setState({
            showMess: `ការស្នើសុំប្រឡងតេស្តសមត្ថភាពត្រូវបានបញ្ចប់!`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          })
        }else if (NextProps.home.verify_member_error.message === 'member-is-underage') {
          const momentMinDate = moment(this.state.schedule_exam.minBirthday);
          const momentMaxDate = moment(this.state.schedule_exam.maxBirthday);
          this.setState({
            showMess:  `សូមអភ័យទោស​! បេក្ខជនត្រូវមានអាយុចន្លោះពី ${momentMinDate.format("DD-MM-YYYY")} រហូតដល់ ${momentMaxDate.format("DD-MM-YYYY")}`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          });
        }else {
          this.setState({
            showMess: `សូមពិនិត្យព័ត៌មានរបស់អ្នកនិងព្យាយាមម្តងទៀត!`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          });
        }
      }
      if (NextProps.home.verify_member && NextProps.home.verify_member !== home.verify_member && NextProps.home.verify_member.message === 'success') {
        const {
          gender,
          showJob,
          showSubjob,
          name,
          phonenumber,
          numberID,
          imageInde,
          imageProfile,
          schedule_exam,
          showTitleCate,
          showTitleJob,
        } = this.state;
        const trimPhone=trimEmptyString(phonenumber);
        const trimName=trimEmptyString(name);
        const trimPass=trimEmptyString(numberID)
        if (gender !== "" && showJob !== "" && showSubjob !== "" && trimName !== "" && trimPhone !== "" && trimPass !== "" && imageInde !== "" && imageProfile !== "" && schedule_exam !== "") {
          //show form review
          var dataPost = {
            "image": imageProfile,
            "imagePass": imageInde,
            "name": trimName,
            "gender": gender,
            "birthday": this.state.birthday,
            "passport": trimPass,
            "subJob": showSubjob,
            "job": showJob,
            "phone": trimPhone,

            "codePay": "",
            "idSchedule_exam": schedule_exam.id,
            "Schedule_exam_Special": schedule_exam.special,
            "cateJobTitle": showTitleCate,
            "subJobTitle": showTitleJob,
            "showProfile": this.state.showProfile || null,
            "showID": this.state.showID || null
          };
          this.setState({
            dataPreviewed: dataPost,
            openPreview: true,
            paymentLoading: false,
          })
        }
      }

      if (NextProps.home.dataUpload_tempotery && NextProps.home.dataUpload_tempotery !== home.dataUpload_tempotery) {
        if (NextProps.home.dataUpload_tempotery.message === 'success') {
          //* ABA
          if (this.state.typePayment === 'ABA') {
            const trimName=trimEmptyString(this.state.name);
            const trimPhone=trimEmptyString(this.state.phonenumber);
            const newDate = moment(new Date()).format('YYYYMMDDHHmmss');
            this.setState({
              tran_id:NextProps.home.dataUpload_tempotery.data
            });
            const tran_id=NextProps.home.dataUpload_tempotery.data;
            const amount=this.state.schedule_exam.price;
            const dataToHash = newDate + 'hrdkorea' + tran_id+amount+trimName+trimPhone+'abapay';
            const keyWordArray = CryptoJS.enc.Utf8.parse(ABA_PAYWAY_KEY);
            const hmacSha512 = CryptoJS.HmacSHA512(dataToHash, keyWordArray);
            const base64EncodedHash = CryptoJS.enc.Base64.stringify(hmacSha512);

            this.setState({
              paymentData: {
                hash: base64EncodedHash,
                firstname: trimName,
                amount: amount,
                phone: trimPhone,
                tran_id: tran_id,
                req_time: newDate,
                merchant_id: 'hrdkorea',
                payment_option:'abapay'
              }
            })
            document.getElementById('hash').value = base64EncodedHash;
            document.getElementById('firstname').value = trimName;
            document.getElementById('amount').value = amount;
            document.getElementById('phone').value =trimPhone;
            document.getElementById('tran_id').value = tran_id
            document.getElementById('req_time').value = newDate
            document.getElementById('merchant_id').value = 'hrdkorea'
            document.getElementById('payment_option').value = 'abapay'
            setTimeout(()=> this.handleCrateTransaction(),2000);
            await window.localStorage.setItem("paymentTran",JSON.stringify({"type":"ABA","tran_id":tran_id}));
            verifyTransactionABA(tran_id,"ABA");
            setTimeout(()=>cancelCerifyTransactionABA(),1000 * 5 * 60) //5min
          }
          //* BAKONG
          else if (this.state.typePayment === 'BAKONG') {
            const trimName=trimEmptyString(this.state.name);
            const trimPhone=trimEmptyString(this.state.phonenumber);
            const newDate = moment(new Date()).format('YYYYMMDDHHmmss');
            this.setState({
              tran_id:NextProps.home.dataUpload_tempotery.data
            });
            const tran_id=NextProps.home.dataUpload_tempotery.data;
            const amount=this.state.schedule_exam.price;
            const dataToHash = newDate + 'hrdkorea' + tran_id+amount+trimName+trimPhone+'bakong';
            const keyWordArray = CryptoJS.enc.Utf8.parse(ABA_PAYWAY_KEY);
            const hmacSha512 = CryptoJS.HmacSHA512(dataToHash, keyWordArray);
            const base64EncodedHash = CryptoJS.enc.Base64.stringify(hmacSha512);

            this.setState({
              paymentData: {
                hash: base64EncodedHash,
                firstname: trimName,
                amount: amount,
                phone: trimPhone,
                tran_id: tran_id,
                req_time: newDate,
                merchant_id: 'hrdkorea',
                payment_option:'bakong'
              }
            })
            document.getElementById('hash').value = base64EncodedHash;
            document.getElementById('firstname').value = trimName;
            document.getElementById('amount').value = amount;
            document.getElementById('phone').value =trimPhone;
            document.getElementById('tran_id').value = tran_id
            document.getElementById('req_time').value = newDate
            document.getElementById('merchant_id').value = 'hrdkorea'
            document.getElementById('payment_option').value = 'bakong'
            setTimeout(()=> this.handleCrateTransaction(),2000);
            await window.localStorage.setItem("paymentTran",JSON.stringify({"type":"BAKONG","tran_id":tran_id}));
            verifyTransactionABA(tran_id,"BAKONG");
            setTimeout(()=>cancelCerifyTransactionABA(),1000 * 5 * 60) //5min
          }
          //* WING
          else if(this.state.typePayment==='WING'){
            const tran_id=NextProps.home.dataUpload_tempotery.data;
            const amount=this.state.schedule_exam.price;
            const dataWing={
              "tran_id": tran_id,
              "amount":`${amount}`,
            }
            this.setState({
              wingPayment:{...dataWing}
            })

            verifyTransactionWING(tran_id);
            // remove check status 5 min because on wing have function refresh QR code
            // setTimeout(()=>cancelCerifyTransactionWING(),1000 * 5 * 60) //5min
            
            await window.localStorage.setItem("paymentTran",JSON.stringify({"type":"WING","tran_id":tran_id}));
            await window.localStorage.setItem("wing",JSON.stringify({...dataWing}));
            this.PaymentViaWing(dataWing);
          }
        }else{
          this.setState({
            showMess:  `សូមអភ័យទោស​ ទិន្នន័យរបស់អ្នកកំពុងជួបបញ្ហាក្នុងការស្នើសុំប្រឡងតេស្តសមត្ថភាព សូមព្យាមនៅពេលក្រោយ!`,
            error: true,
            paynow: false,
            modalWing: false,
            paymentLoading: false
          });
        }
      }
      //* Verify ABA + BAKONG purchase
      if (NextProps.home.checkTranABA && NextProps.home.checkTranABA !== home.checkTranABA) {
        if(NextProps.home.checkTranABA && NextProps.home.checkTranABA.status===0){
          const {phonenumber}=this.state;
          const tripPhone=trimEmptyString(phonenumber);
          const storage_ = localStorage.getItem('persist:@local10010');
          const storage = JSON.parse(storage_);
          const user = JSON.parse(storage.user);

          cancelCerifyTransactionABA();
          this.props.web_push_back_f({
            tran_id:this.state.tran_id,
            amount:this.state.schedule_exam.price,
            device:"WEBSITE",
            phone:tripPhone,
            schedule:this.state.schedule_exam.id,
            userId:user.login.data.id,
            payment:this.state.typePayment
          });
        }else if(NextProps.home.checkTranABA && NextProps.home.checkTranABA.status===6){
          this.setState({abaErrorCount:this.state.abaErrorCount+1});
          if(this.state.abaErrorCount % 10===1){
            this.props.profile_f({schedule:this.state.schedule_exam.id});
          }
        }
      }

      //* Verify WING
      if (NextProps.home.checkTranWING && NextProps.home.checkTranWING !==home.checkTranWING){
        if(NextProps.home.checkTranWING?.errorCode==='200' && NextProps.home.checkTranWING?.order_reference_no !==''){
          const {phonenumber}=this.state;
          const tripPhone=trimEmptyString(phonenumber);
          const storage_ = localStorage.getItem('persist:@local10010');
          const storage = JSON.parse(storage_);
          const user = JSON.parse(storage.user);
          cancelCerifyTransactionWING();
          this.props.web_push_back_f({
            tran_id:NextProps.home.checkTranWING?.order_reference_no,
            amount:this.state.schedule_exam.price,
            device:"WEBSITE",
            phone:tripPhone,
            schedule:this.state.schedule_exam.id,
            userId:user.login.data.id,
            payment:'wing',
            detail:JSON.stringify(NextProps.home.checkTranWING)  || ''
          });
        }
      }

      //* push back success
      if (NextProps.home.pushBackWeb && NextProps.home.pushBackWeb !== home.pushBackWeb) {
        if(NextProps.home.pushBackWeb && NextProps.home.pushBackWeb.message==='success'){
          await setTimeout(() => window.location.replace('/result'), 500);
        }
      }

      //* open wing SDK
      if (NextProps.home.wingHash && NextProps.home.wingHash !== home.wingHash) {
        if(NextProps.home.wingHash && NextProps.home.wingHash.tran_id!=='' && NextProps.home.wingHash.token!==''){
          const base64Hash = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(NextProps.home.wingHash.token));
          const url=`${WING_ONLINE_URL}?token=${base64Hash}`;
          this.setState({
            urlEncodedData:url
          });

          setTimeout(() => { this.setState({ modalWing: true,paymentLoading:false }) }, 1000);
        }
      }
     
      if (NextProps.profile.profile_ && NextProps.profile.profile_ !== profile.profile_) {
        if (NextProps.profile.profile_.message === "empty") {
          if (app && app.schedule_exam && app.schedule_exam.data.length > 0) {
            if (app.schedule_exam.data[0].open === 0) {
              this.setState({
                redirect: {
                  page: "/noexam",
                  action: true
                }
              })
            }
          } else {
            this.setState({
              redirect: {
                page: "/noexam",
                action: true
              }
            })
          }
        }

        if (NextProps.profile.profile_ && NextProps.profile.profile_.message === 'success') {
          this.setState({
            redirect: {
              page: "/result",
              action: true
            }
          })
        }
      }
      
    } catch (e) {
    }
  }
  async handleCheckTranIdStartup(){
    const dataPayment=await window.localStorage.getItem("paymentTran");
    if(dataPayment){
      const parseDataPayment=JSON.parse(dataPayment);
      if(parseDataPayment && parseDataPayment?.type && parseDataPayment?.tran_id){
        this.setState({paymentLoading:true});
        setTimeout(()=>this.setState({paymentLoading:false}),5*1000);//8s 
        if(parseDataPayment.type==="ABA"){
          verifyTransactionABA(parseDataPayment.tran_id,"ABA");
          setTimeout(()=>cancelCerifyTransactionABA(),17*1000); //17s
        }
        if(parseDataPayment.type==="BAKONG"){
          verifyTransactionBAKONG(parseDataPayment.tran_id,"BAKONG");
          setTimeout(()=>cancelCerifyTransactionBAKONG(),17*1000);//17s
        }
      }
    }
  }
  previewReady() {
    const { dataPreviewed } = this.state;
    var dataPost = {
      "image": dataPreviewed.image,
      "imagePass": dataPreviewed?.imagePass,
      "name": dataPreviewed.name,
      "gender": dataPreviewed.gender,
      "birthday": dataPreviewed.birthday,
      "passport": dataPreviewed.passport,
      "subJob": dataPreviewed.subJob,
      "job": dataPreviewed.job,
      "phone": dataPreviewed.phone,
      "device":"WEBSITE",
      "schedule":this.state.schedule_exam.id,
      "payment": `${this.state.typePayment}`.toLocaleUpperCase(),
      "amount":this.state.schedule_exam.price
    }

    this.setState({
      openPreview: false,
      paymentLoading: true,
    });
    this.props.submit_form_tempotery_f(dataPost);

    
  }
  _loopDropmenu(array, state) {
    var rs = [];
    if (array && array.length > 0) {
      array.map((one, inex) =>
        rs = [...rs, <Dropdown.Item
          key={inex}
          onClick={() => {
            state === 'showJob' ? this.setState({ [state]: one['value'], showTitleCate: one['title'],showTitleJob:'',showSubjob:''}) :
              state === 'showSubjob' ? this.setState({ [state]: one['value'], showTitleJob: one['title'] }) :
                this.setState({ [state]: one['value'] });
          }}
        >{one['title']}</Dropdown.Item>]
      );
    } return rs
  }
  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
    };
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
      type: mime
    });
  }
  _dropdown(array, label, state) {
    return (
      <Dropdown >
        <Dropdown.Toggle
          style={(label === "ថ្ងៃ" || label === "ខែ" || label === 'ឆ្នាំ' || label === 'ប្រភេទការងារ' || label === 'ការងារបន្ទាប់បន្សំ' || label === 'ភេទ') ? { color: 'gray' } : { color: 'black' }}
          className="drop_down_gender">
          {label}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            this._loopDropmenu(array, state)
          }
        </Dropdown.Menu>
      </Dropdown>
    )
  }
  _payment(typePayment) {
    const {
      showDay,
      showMonth,
      showYear,
      comfirmurInfo,
      pysical,
      experince,
      gender,
      showJob,
      showSubjob,
      name,
      phonenumber, 
      numberID,
      imageInde,
      imageProfile
    } = this.state;
    this.setState({ typePayment: typePayment,modalWing:false });
    const tripName=trimEmptyString(name);
    const tripPhone=trimEmptyString(phonenumber);
    const tripPassport=trimEmptyString(numberID);
    if(isValidPhoneNumber(tripPhone)){
      if (showDay !== "" && showMonth !== "" && showYear !== "") {
      const birthday = showDay + "-" + showMonth + "-" + showYear;
      const formattedDate = moment(birthday, "DD-MM-YYYY");
        if ( comfirmurInfo === true && pysical === true && experince === true && tripName !== "" && gender !== "" && showJob !== "" && showSubjob !== "" && tripPassport !== "" && tripPhone !== "" && imageInde !== "" && imageProfile !== "" && imageInde!==null && imageProfile!==null) {
          if (formattedDate._isValid) {
            const momentMinDate = moment(this.state.schedule_exam.minBirthday);
            const momentMaxDate = moment(this.state.schedule_exam.maxBirthday);
            const isBetween = formattedDate.isBetween(momentMinDate, momentMaxDate, null, '[]');
              if(isBetween){
            const data={
              "exam":this.state.schedule_exam.id,
              "name":tripName,
              "birthday":formattedDate.format("YYYY-MM-DD"),
              "passport":tripPassport
            }
            //loading set payment require
            this.setState({ 
              paymentLoading: true,
              birthday:formattedDate.format("YYYY-MM-DD") 
            });
            this.props.verify_member_f(data);
            }else{
              this.setState({
                showMess:  `សូមអភ័យទោស​! បេក្ខជនត្រូវមានអាយុចន្លោះពី ${momentMinDate.format("DD-MM-YYYY")} រហូតដល់ ${momentMaxDate.format("DD-MM-YYYY")}`,
                error: true,
              })
            }
          } else {
            var result = moment((showDay + "-" + showMonth).toString(), "YYYY-MM").daysInMonth();
            this.setState({
              showMess: 'សូមពិនិត្យថ្ងៃខែឆ្នាំកំណើតរបស់អ្នកឡើងវិញ!'+`${birthday}`,
              error: true,
              endDate: result
            })
          }
        } else{
            this.setState({
                showMess: 'សូមបំពេញព័ត៌មានរបស់អ្នកអោយបានត្រឹមត្រូវ!',
                error: true,
              });
          }
      } else {
          this.setState({
            showMess: 'សូមបំពេញព័ត៌មានរបស់អ្នកអោយបានត្រឹមត្រូវ!',
            error: true
          })
        }
    }else{
      this.setState({
          showMess: 'សូមពិនិត្យលេខទូរស័ព្ទរបស់អ្នកឡើងវិញ!',
          error: true,
        });
    }
  }
  _settingDay(le, ye) {
    var rs = [];
    for (var i = 1; i <= this.state.endDate; i++) {
      rs.push({ title: i.toString(), value: i })
    }
    return rs;
  }

  _settingMonth() {
    var months = MONTH;
    var rs = [];
    for (var i = 0; i < months.length; i++) {
      rs.push({ title: months[i], value: (i + 1).toString() })
    }
    return rs;
  }
  _settingYear() {
    const { schedule_exam } = this.state;
    let years = []
    if(schedule_exam.minBirthday!=='' && schedule_exam.maxBirthday){
      const YDateStart = moment(schedule_exam.minBirthday).format('YYYY');
      const YDateEnd = moment(schedule_exam.maxBirthday).format('YYYY');
      if (YDateStart <= YDateEnd) {
        for (let i = Number(YDateStart); i <= Number(YDateEnd); i++) {
          years.push(
            {
              title: `${i}`,
              value: `${Number(i)}`,
            })
        }
      }
    }
    return years;
  }
  handleChange(event) {
    this.setState({
      imageInde: event.target.files[0],
      showID: URL.createObjectURL(event.target.files[0])
    })
  }
  handleChangeProfile(event) {
    this.setState({
      imageProfile: event.target.files[0],
      showProfile: URL.createObjectURL(event.target.files[0])
    });
  }

  _logout() {
    localStorage.clear('persist:@local10010');
    this.forceUpdate();
    setTimeout(() => { window.location.replace('/') }, 2000)
  }
  handleJobs (jobs) {
    let result= [];
    if (jobs?.length > 0) {
      jobs.map((job) => {
        if (job.isMain === 1) {
          result.push(
            { title: job.name, value: `${job.id}` }
          )
        }
      })
    }
    return result;
  }
  handleSubJob (jobs, mainId){
    let result=[];
    if (jobs?.length > 0 && mainId && mainId>0) {
      jobs.map((job) => {
        if (Number(job.mainId) === Number(mainId)) {
          result.push(
            { title: job.name, value: `${job.id}` }
          )
        }
      })
    }
    return result;
  }
  handleClose () {
    this.props.profile_f({schedule:this.state.schedule_exam.id});
    this.setState({urlEncodedData:'',modalWing:false});
    cancelCerifyTransactionWING();
  };
  render() {
    const { redirect, modalWing } = this.state;
    return (
      <div >
      {modalWing ?
          <div style={{zIndex:1000, position: 'absolute', overflow: 'hidden',backgroundColor:'#E8EAF2',minWidth:'100%',minHeight:'100%'}}>
              <Iframe url={this.state.urlEncodedData}
                  allowFullScreen
                  loading={"សូមរង់ចាំ"}
                  styles={{
                    width:"100%",
                    height:"100vh",
                    minHeight:"100vh"
                  }}
                /> 
            </div>
        : null
        }
      <div className="container" style={modalWing===true?{display:'none'}:{}}>
        <div id="aba_main_modal" className="aba-modal">
          <div className="aba-modal-content">
            <form method="POST" target="aba_webservice" action={`${ABA_API_PURCHASE}`} id="aba_merchant_request">
              <input type="hidden" name="hash" value={`${this.state.paymentData.hash}`} id="hash" />
              <input type="hidden" name="tran_id" value={`${this.state.paymentData.tran_id}`} id="tran_id" />
              <input type="hidden" name="amount" value={`${this.state.paymentData.amount}`} id="amount" />
              <input type="hidden" name="firstname" value={`${this.state.paymentData.firstname}`} id="firstname" />
              <input type="hidden" name="phone" value={`${this.state.paymentData.phone}`} id="phone" />
              <input type="hidden" name="req_time" value={`${this.state.paymentData.req_time}`} id="req_time" />
              <input type="hidden" name="merchant_id" value={`${this.state.paymentData.merchant_id}`} id="merchant_id" />
              <input type="hidden" name="payment_option" value={`${this.state.paymentData.payment_option}`} id="payment_option" />
              <input type="button" id="checkout_button" style={{ opacity: 0 }} />
            </form>
          </div>
        </div>
        
        {
          this.props.profile.loading ? <Loading />:null
        }
        {
          this.state.paymentLoading ? <MyLoadingPaymentSet />:null
        }

        {redirect.action && redirect.page !== "" &&
          <Redirect to={redirect.page} />
        }
        {
          this.state.error && this.state.showMess ?
            <AlertMessage
              closeOnClick={
                setTimeout(() => { this.setState({ error: false, showMess: false }) }, 8000)
              }
              styleMessage={COLORMESSAGE.error}
              message={this.state.showMess}
            />
            : null
        }
        {
          this.state.success ?
            <AlertMessage
              closeOnClick={
                setTimeout(() => { this.setState({ success: false }) }, 8000)
              }
              styleMessage={COLORMESSAGE.succees}
              message={this.state.showMess}
            />
            : null
        }
        <Row className="fixed_nav" >
          <Header
            logout={
              () => this._logout()
            }
            notication={true}
          />
        </Row>
        <Row className="form_submit">
          <Col xl={3} lg={3} md={2} sm={1} xs={0} >
            
          </Col>
          <Col xl={6} lg={6} md={8} sm={10} xs={12} >
            <h1 className="maintitle">
              {this.state.schedule_exam.name || ''}
            </h1>
            <div>
              <input
                onChange={(value) => {
                  const re = /^[a-z\ A-Z\b]+$/;
                  if (value.target.value === '' || re.test(value.target.value)) {
                    this.setState({
                      name: value.target.value
                    })
                  }
                }
                }
                value={this.state.name.toString()}
                placeholder="នាមត្រកូល និងនាមខ្លួនជាអក្សរឡាតាំង"
                className="input_form"
              />
              {
                this._dropdown(GENDER, this.state.gender === "" ? 'ភេទ' : findGender(GENDER, this.state.gender), 'gender')
              }
              <Row style={{ padding: '0px', margin: '0px' }}>
                <Col xs={4} className="_day_home" >
                  {
                    this._dropdown(this._settingDay(this.state.months, this.state.year), this.state.showDay === '' ? 'ថ្ងៃ' : this.state.showDay, 'showDay')
                  }
                </Col>
                <Col xs={4} className="_month_home">
                  {
                    this._dropdown(this._settingMonth(), this.state.showMonth === "" ? 'ខែ' : findMonth(MONTH, this.state.showMonth), 'showMonth')
                  }
                </Col>
                <Col xs={4} className="_year_home">
                  {
                    this._dropdown(this._settingYear(), this.state.showYear === "" ? 'ឆ្នាំ' : this.state.showYear, 'showYear')
                  }
                </Col>
              </Row>
              {
                this._dropdown(this.handleJobs(this.state.jobs), this.state.showJob !== "" ? findItem(this.handleJobs(this.state.jobs), this.state.showJob) : 'ប្រភេទការងារ', 'showJob')
              }
              {
                this._dropdown(this.handleSubJob(this.state.jobs,this.state.showJob), this.state.showSubjob !== "" ? findItem(this.handleSubJob(this.state.jobs,this.state.showJob), this.state.showSubjob) : 'ការងារបន្ទាប់បន្សំ', 'showSubjob')
              }
              <input
                onChange={(value) => {
                  const re = /^[0-9\b]+$/;
                  if (value.target.value === '' || re.test(value.target.value)) {
                    var a = String(value.target.value)
                    if (value.target.value[0] !== '0' && value.target.value[0]) {
                      a = '0' + String(value.target.value)
                    }
                    this.setState({
                      phonenumber: a,

                    })
                  }
                }}
                value={this.state.phonenumber.toString()}
                placeholder="លេខទូរស័ព្ទ(012 2xxxxxx)"
                className="input_form" />
              <input
                onChange={(value) => {
                  if (this.state.schedule_exam.special === 0) {
                    const re = /^[0-9 A-Z a-z\b]+$/;
                    if (value.target.value === '' || re.test(value.target.value)) {
                      this.setState({
                        numberID: value.target.value
                      })
                    }
                  } else {
                    const re = /^[0-9 A-Z\b]+$/;
                    if (value.target.value === '' || re.test(value.target.value)) {
                      this.setState({
                        numberID: value.target.value
                      })
                    }
                  }
                }}
                value={this.state.numberID.toString()}
                placeholder={this.state.schedule_exam.special === 1 ? "លេខលិខិតឆ្លងដែន(N1234567)" : "លេខអត្តសញ្ញាណប័ណ្ណ ឬលិខិតឆ្លងដែន"}
                className="input_form" />
              <Form.Check
                className="check_agre"
                onChange={() => this.setState({ pysical: !this.state.pysical })}
                style={{ textAlign: 'left' }}
                type={'checkbox'}
                id={'default-checkbox_'}
                label={'មានកាយសម្បទាគ្រប់គ្រាន់ និងមិនមានបញ្ហាងងឹតពណ៌'}
              />
              <Form.Check
                className="check_agre"
                onChange={() => this.setState({ experince: !this.state.experince })}
                style={{ textAlign: 'left' }}
                type={'checkbox'}
                id={'default-checkbox__'}
                label={'មិនធ្លាប់ធ្វើការ ឬស្នាក់នៅសាធារណរដ្ឋកូរ៉េ (E-9,E-10) លើសពី៥ឆ្នាំ'}
              />
              <Form.Check
                onChange={() => this.setState({ comfirmurInfo: !this.state.comfirmurInfo })}
                style={{ textAlign: 'left' }}
                className="check_agre"
                type={'checkbox'}
                id={'default-checkbox___'}
                label={'ខ្ញុំបាទ/នាងខ្ញុំសូមទទួលខុសត្រូវចំពោះព័ត៌មានផ្ទាល់ខ្លួន និងយល់ព្រមចំពោះការប្រើប្រាស់ព័ត៌មានផ្ទាល់ខ្លួនរបស់ខ្ញុំ'}
              />
              <p className="lbl_">
                <span style={{ color: 'red' }}>*</span> រូបថតបេក្ខជន (3.5 x 4.5 cm)
              </p>
              <label className="div_profileImage" htmlFor="upload-photo">
                <img
                  alt='profile'
                  src={this.state.showProfile === null ? IMAGE.adduser : this.state.showProfile}
                  className="profile_image_pro" />
              </label>
              <div >
                <input
                  style={{ display: 'none' }}
                  onChange={this.handleChangeProfile}
                  type="file"
                  name="photo_"
                  accept="image/x-png, image/gif, image/jpeg"
                  id="upload-photo" />
              </div>
              <p className="lbl_">
                <span style={{ color: 'red' }}>*</span>{this.state.schedule_exam.special === 1 ? "រូបថតលិខិតឆ្លងដែន" : "រូបថតអត្តសញ្ញាណប័ណ្ណ ឬលិខិតឆ្លងដែន"}
              </p>
              <label className="div_identify" htmlFor="upload-photo-idcart">
                <img
                  alt='ID card'
                  src={this.state.showID === null ? IMAGE.adduser : this.state.showID}
                  className="profile_image" />
              </label>
              <div >
                <input
                  style={{ display: 'none' }}
                  onChange={this.handleChange}
                  type="file"
                  name="photo"
                  id="upload-photo-idcart"
                  accept="image/x-png, image/gif, image/jpeg" />
              </div>
              <h5 style={{ marginTop: 40, marginBottom: 20 }} className="titleButtom_"><span style={{ color: 'red' }}> * </span>បេក្ខជនត្រូវបង់ប្រាក់ចំនួន {Number(this.state.schedule_exam.price).toFixed(2)} ដុល្លារអាមេរិចជូនទៅHRD-Korea ដើម្បីចុះឈ្មោះសុំសិទ្ធិប្រឡងតេស្ត</h5>
              <div style={{ marginBottom: 50 }}>
                {
                  this.props.app && 
                  this.props.app.schedule_exam && 
                  this.props.app.schedule_exam['data'] && 
                  this.props.app.schedule_exam['data'].length > 0  &&
                  this.props.app.schedule_exam['data'][0]?.open===1 &&
                  this.props.app.schedule_exam['data'][0]?.status===1
                  ?
                    <div>
                      {
                        this.props.app.schedule_exam['data'][0]['aba'] === 1 ?
                          <Button
                            style={{
                              border: 0,
                              backgroundColor: 'white',
                              width: '100%',
                              outline: null,
                              padding: 0,
                              margin: 0
                            }
                            }
                            onClick={() => { this._payment('ABA') }}>
                            <Row style={{ marginTop: 5,padding:0 }}>
                              <div style={{ display: 'flex' }}>
                                <img
                                  alt='ABA Bank'
                                  style={{ height: 50, resizeMode: 'contain', marginTop: 'auto', marginBottom: 'auto' }}
                                  src={IMAGE.aba} />
                                <div style={{ display: 'flex', marginLeft: 10 }}>
                                  <div style={{ marginTop: 12 }}>
                                    <p style={{
                                      display: 'block',
                                      fontSize: 18,
                                      fontWeight: 'bold',
                                      color: 'black',
                                      marginTop: 2,
                                      marginLeft: 2,
                                      textAlign: 'left'
                                    }}>
                                      ABA PAY
                                       <span style={{
                                        display: 'block',
                                        fontSize: 14,
                                        color: 'gray',
                                        fontWeight:'lighter'
                                      }}>
                                        ស្គែន ដើម្បីទូទាត់តាម ABA Mobile
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </Button> : null
                      }
                      {
                        this.props.app.schedule_exam['data'][0]['bakong'] === 1 ?
                          <Button
                            style={{
                              border: 0,
                              backgroundColor: 'white',
                              width: '100%',
                              outline: null,
                              padding: 0,
                              margin: 0
                            }
                            }
                            onClick={() => { this._payment('BAKONG') }}>
                            <Row style={{ marginTop: 5 }}>
                              <div style={{ display: 'flex' }}>
                                <img
                                  alt='KHQR'
                                  style={{ height: 50, resizeMode: 'contain', marginTop: 'auto', marginBottom: 'auto' }}
                                  src={IMAGE.khqr} />
                                <div style={{ display: 'flex', marginLeft: 10 }}>
                                  <div style={{ marginTop: 12 }}>
                                    <p style={{
                                      display: 'block',
                                      fontSize: 18,
                                      fontWeight: 'bold',
                                      color: 'black',
                                      marginTop: 2,
                                      marginLeft: 2,
                                      textAlign: 'left'
                                    }}>
                                      KHQR
                                      <span style={{
                                        display: 'block',
                                        fontSize: 14,
                                        color: 'gray',
                                        fontWeight:'lighter'
                                      }}>
                                        ស្កេន ដើម្បីទូទាត់ជាមួយកម្មវិធីធនាគារដែលជាសមាជិក
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </Button> : null
                      }
                      {
                        this.props.app.schedule_exam['data'][0]['wing'] === 1 ?
                          <Button
                            style={{
                              border: 0,
                              backgroundColor: 'white',
                              width: '100%',
                              padding: 0,
                              margin: 0,
                              marginTop: -10
                            }
                            }
                            onClick={() => { this._payment('WING') }}
                          >
                            <Row style={{ marginTop: 5 }}>
                              <div style={{ display: 'flex' }}>
                                <img
                                  alt='WING BANK'
                                  style={{ height: 50, resizeMode: 'contain', marginTop: 'auto', marginBottom: 'auto' }}
                                  src={IMAGE.wing} />
                                <div style={{ display: 'flex', marginLeft: 10 }}>
                                  <div style={{ marginTop: 10 }}>
                                    <p style={{
                                      display: 'block',
                                      fontSize: 18,
                                      fontWeight: 'bold',
                                      color: 'black',
                                      textAlign: 'left',
                                    }}>
                                      Wing Bank
                                      <span style={{
                                        display: 'block',
                                        fontSize: 14,
                                        color: 'gray',
                                        fontWeight:'lighter'
                                      }}>
                                        ចុច​ ដើម្បីទូទាត់តាម Wing Bank
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Row>
                          </Button> : null
                      }
                    </div>
                    : null
                }
              </div>
            </div>
          </Col>
          <Col xl={3} lg={3} md={2} sm={1} xs={0}></Col>
        </Row>
        <ModalPreview
          data={this.state.dataPreviewed}
          handleSave={() => this.previewReady()}
          handleClose={() => this.setState({ openPreview: false })}
          open={this.state.openPreview} />
        {/* <button
        onClick={()=>{
          this.setState({
            gender:"Male",
            name:"BIENSOTHEARITH",
            phonenumber:"070594606",
            numberID:"070594606",
            showDay:'3',
            showMonth:'2',
            showYear:'2004',
            showJob:'1',
            showSubjob:'8'
          })
        }}
        >AAAA</button>  */}
      </div>
      </div>
    );
  }
}
