import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScreenLogin from './containers/ScreenLoginContainer';
// import ScreenLoginSeasonal from './containers/ScreenLoginSeasonalContainer';
import ScreenSignup from './containers/ScreenSignupContainer';
// import ScreenSignupSeasonal from './containers/ScreenSignupSeasonalContainer';
import ScreenNotification from './containers/ScreenNotificationContainer'
import ScreenForm from './containers/ScreenFormContainer';
// import ScreenFormSWP from './containers/ScreenFormSWPContainer';
import ScreenResult from './containers/ScreenResultContainer';
// import ScreenResultSWP from './containers/ScreenResultSWPContainer';
// import ScreenHome from './containers/ScreenHomeContainer';
import ScreenNoSchadule from './screen/ScreenNoSchadule';
// import ScreenNoScheduleSWP from './screen/ScreenNoScheduleSWP';

import ScreenDisable from './screen/ScreenDisable';
import ScreenWing from './screen/ScreenWing';

import { APP_INFO, APP_REFRESH, APP_SCHEDULE, 
  // APP_SCHEDULE_SEASONAL
} from './modules/app/reducer';
import { USER_LOGOUT } from './modules/user/reducer'
import NotFoundPage from './screen/NotFountPage'
import Loading from './components/loading'
import store from './store'
import ScreenYoutube from './screen/ScreenYoutube';
import { timeExpireToken } from './utils/timeExpireToken';
import { onServiceNewToken } from './utils/renewToken';
import 'react-notifications/lib/notifications.css';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schadule: false,
      loading: true,
      registerStatus: true,
      statusExam: false,
      disableApp:false
    }
  }
  componentWillMount() {
    if (window.performance) {
      if (performance.navigation.type === 1 || performance.navigation.type === 0) {
        store.dispatch({ type: APP_INFO });
        // * EPS
        store.dispatch({ type: APP_SCHEDULE });
        // * SEASONAL
        // store.dispatch({ type: APP_SCHEDULE_SEASONAL });
        store.dispatch({ type: APP_REFRESH });
      }
    }
  }
  componentDidMount() {
    setTimeout(() => {
      var a = store.getState();
      if (a && a.app && a.app.schedule_exam && a.app.schedule_exam.message!== "") {
        if (a.app.schedule_exam.message === 'false') {
          var storage_ = localStorage.getItem('persist:@local10010');
          var storage = JSON.parse(storage_);
          if (storage) {
            var user = JSON.parse(storage.user);
            var newUser = {
              ...user,
              login: {}
            }
            var newStore = {
              ...storage,
              'user': JSON.stringify(newUser)
            }
            localStorage.setItem('persist:@local10010', JSON.stringify(newStore));
          }
          store.dispatch({ type: APP_REFRESH });
        }
        if (a && a.app && a.app.schedule_exam && a.app.schedule_exam && a.app.schedule_exam.data && a.app.schedule_exam.data.length > 0) {
          this.setState({
            registerStatus: a.app.schedule_exam.data[0].open === 1 || a.app.schedule_exam.data[0].open === "1" ? true : false,
            statusExam: a.app.schedule_exam.data[0].status === 1 || a.app.schedule_exam.data[0].status === "1" ? true : false,
            loading: false
          })
        } else {
          this.setState({
            registerStatus: false,
            statusExam: false,
            loading: false
          })
        }

      }
      if (a && a.app && a.app.appInfo) {
        if(a.app.appInfo?.data?.[0]?.deviceStatus ===0){
          this.setState({
            disableApp:true
          })
        }else{
            this.setState({
              disableApp:false
            })
        }
      }
    }, 3000);
    if (window.location.pathname.indexOf("/dashboard") > -1) {
      const cookie = store.getState();
      const isToken = cookie?.user?.login_admin?.token;
      const exp = cookie?.user?.login_admin?.data?.exp;

      if (isToken && exp) {
        const isExpired = timeExpireToken(isToken, exp);
        if (!isExpired) {
          onServiceNewToken();
        } else {
          store.dispatch({ type: USER_LOGOUT });
          setTimeout(() => window.location.replace('/login'), 1000);
        }
      }
    }
    // 55min
    if (window.location.pathname.indexOf("/dashboard") > -1) {
      setInterval(() => {
        const cookie = store.getState();
        const isToken = cookie.user.login_admin.token;
        const exp = cookie.user.login_admin.data.exp;
        if (isToken && exp) {
          const isExpired = timeExpireToken(isToken, exp);
          if (!isExpired) {
            onServiceNewToken();
          } else {
            store.dispatch({ type: USER_LOGOUT });
            setTimeout(() => window.location.replace('/login'), 1000);
          }
        }
      }, 6000 * 55);
      // return clearInterval(interval)
    }
  }

  render() {
    return (
      <div>
        {
          this.state.loading && <Loading />
        }
          {
            // * EPS
            this.state.registerStatus===true &&
            this.state.statusExam===true &&
            this.state.disableApp===false?
              <Router>
              <Switch>
                <Route exact path="/wing" component={ScreenWing}/>
                <Route exact path="/signup" component={ScreenSignup} />
                <Route exact path="/" component={ScreenLogin} />
                <Route exact path="/notification" component={ScreenNotification} />
                <Route exact path="/form" component={ScreenForm} />
                <Route exact path="/result" component={ScreenResult} />
                <Route exact path="/video/:id?" component={ScreenYoutube} />
                <Route exact path="/noexam" component={() => <ScreenNoSchadule isPageNotShowLogin={true} />} />
                <Route exact path="/disable" component={() => <ScreenDisable isPageNotShowLogin={true} />} />
                <Route component={NotFoundPage} />
              </Switch>
            </Router>:
            this.state.statusExam===true &&
            this.state.disableApp===false &&
            this.state.registerStatus===false ?
            <Router>
              <Switch>
                <Route exact path="/signup" component={ScreenSignup} />
                <Route exact path="/" component={ScreenLogin} />
                <Route exact path="/notification" component={ScreenNotification} />
                <Route exact path="/form" component={ScreenForm} />
                <Route exact path="/result" component={ScreenResult} />
                <Route exact path="/video/:id?" component={ScreenYoutube} />
                <Route exact path="/noexam" component={() => <ScreenNoSchadule isPageNotShowLogin={true} />} />
                <Route exact path="/disable" component={() => <ScreenDisable isPageNotShowLogin={true} />} />
                <Route component={NotFoundPage} />
              </Switch>
            </Router>
            :
  
            this.state.disableApp===true?
            <Router>
            <Switch>
              <Route component={ScreenDisable} />
            </Switch>
          </Router>:
            <Router>
               <Switch>
                <Route component={() => <ScreenNoSchadule isPageNotShowLogin={true} />} />
               </Switch>
             </Router>
          }

          {
          // * SEASONAL
          // this.state.disableApp===false?
          //   <Router>
          //   <Switch>
          //     {/* <Route exact path="/signup" component={ScreenSignup} /> */}
          //     <Route exact path="/signup" component={ScreenSignupSeasonal} />
          //     {/* <Route exact path="/" component={ScreenLogin} /> */}
          //     <Route exact path="/" component={ScreenLoginSeasonal} />
          //     <Route exact path="/notification" component={ScreenNotification} />
          //     <Route exact path="/form" component={ScreenForm} />
          //     <Route exact path="/result" component={ScreenResult} />
          //     <Route exact path="/result-seasonal" component={ScreenResultSWP} />
          //     <Route exact path="/video/:id?" component={ScreenYoutube} />
          //     <Route exact path="/noexam" component={() => <ScreenNoSchadule isPageNotShowLogin={true} />} />
          //     <Route exact path="/noexam-seasonal" component={() => <ScreenNoScheduleSWP isPageNotShowLogin={true} />} />
          //     <Route exact path="/disable" component={() => <ScreenDisable isPageNotShowLogin={true} />} />
          //     {/* <Route exact path="/home" component={ScreenHome}/> */}
          //     <Route exact path="/form-swp" component={ScreenFormSWP} />
          //     <Route component={NotFoundPage} />
          //   </Switch>
          // </Router>:
          // <Router>
          // <Switch>
          //   <Route component={ScreenDisable} />
          // </Switch>
          // </Router>
          }
      </div>
    )
  }
}

