import { success, error, abort } from "redux-saga-requests";

export const PAYMENT = 'PAYMENT';
export const payment_f = (payload) =>({
    type: PAYMENT,
    payload
});
const initialState = {
    pending: false,
    error: false,
};

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
    //PAYMENT
    case PAYMENT:{
        return{...state,pending:true};
        }
        case success(PAYMENT):{
            return{
                ...state,
                ...action.payload,
                pending:false};
        }
        case error(PAYMENT):{
            return{...state,
                error:true,
                pending:false,
                ...action.payload
            };
        }
        case abort(PAYMENT):{
            return{...state,pending:false};
        }
        
    /**
     * DEFAULT_CASE
     */
    default:
        return state;
    }
};
export default paymentReducer;